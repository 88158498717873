import React, {Component} from 'react';

export default class ReportItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overallTransactions: {
                authenticationCount: 0,
                captureDataCount: 0,
                barcodeCount: 0,
                totalTransactionsCount: 0
            }
        }
    }

    componentWillMount() {
        if (this.props.report) {
            let overallTransactions = {
                authenticationCount: 0,
                captureDataCount: 0,
                barcodeCount: 0,
                totalTransactionsCount: 0
            };
            this.props.report.transactions.map(transaction => {
                overallTransactions.authenticationCount += parseInt(transaction.authenticationCount);
                overallTransactions.captureDataCount += parseInt(transaction.captureDataCount);
                overallTransactions.barcodeCount += parseInt(transaction.barcodeCount);
                overallTransactions.totalTransactionsCount = parseInt(overallTransactions.authenticationCount) + parseInt(overallTransactions.captureDataCount) + parseInt(overallTransactions.barcodeCount);
            });
            this.setState({overallTransactions});
        }
    }

    render() {
        if (!this.props.report) return null;
        let {report} = this.props;

        return (
            <div className="rep__item">

                <div className="rep__designation">
                    <h2 className="rep__name">{report.account.name}</h2>
                    <span className="rep__id">Customer ID: {report.account.customerId}</span>
                </div>
                <div className="rep__head">
                    <div className="rep__row">
                        <div className="rep__cell sub">Subscription</div>
                        <div className="rep__cell type">Type</div>
                        <div className="rep__cell auth">Authentication</div>
                        <div className="rep__cell data">Data Capture</div>
                        <div className="rep__cell bar">Barcode Only</div>
                        <div className="rep__cell total">Total</div>
                    </div>

                </div>
                <div className="rep__body">
                    {report.transactions.map(transaction => {
                        let totalTransactionsCount = parseInt(transaction.authenticationCount) + parseInt(transaction.captureDataCount) + parseInt(transaction.barcodeCount);
                        return <div key={transaction.subscription.id} className="rep__row">
                            <div className="rep__cell sub">{transaction.subscription.name}</div>
                            <div className="rep__cell type">{transaction.subscription.licenseType === 1 ? 'Production' : 'Development'}</div>
                            <div className="rep__cell auth">{transaction.authenticationCount}</div>
                            <div className="rep__cell data">{transaction.captureDataCount}</div>
                            <div className="rep__cell bar">{transaction.barcodeCount}</div>
                            <div className="rep__cell total">{totalTransactionsCount}</div>
                        </div>
                    })}
                </div>
                <div className="rep__foot">
                    <div className="rep__row">
                        <div className="rep__cell sub">Total</div>
                        <div className="rep__cell type"></div>
                        <div className="rep__cell auth">{this.state.overallTransactions.authenticationCount}</div>
                        <div className="rep__cell data">{this.state.overallTransactions.captureDataCount}</div>
                        <div className="rep__cell bar">{this.state.overallTransactions.barcodeCount}</div>
                        <div className="rep__cell total">{this.state.overallTransactions.totalTransactionsCount}</div>
                    </div>

                </div>

            </div>
        )
    }

}