import React, { Component, Fragment } from 'react';
import {Provider, useSelector} from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'

import {connect} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import IdleTimer from 'react-idle-timer'
//import Modal from 'react-modal';
import Modal from './screens/shared/Modal';
import Cookies from 'universal-cookie';
import moment from 'moment';
//global window
import { processSilentRenew } from 'redux-oidc';

import { OidcProvider } from 'redux-oidc';
import './styles/main.css';
import './index.css'

import Auth from './screens/Auth';
import Dashboard from './screens/Dashboard'
import CallbackPage from './screens/Auth/Callback';
import userManager from "./utils/userManager";
import PrivateRoute from "./utils/PrivateRoute";
import Account from "./screens/Account";
import {UserManager} from "oidc-client";
import SilentRenew from "./screens/Auth/SilentRenew";
import IdleModalComponent from "./IdleModalComponent";
import {Toaster} from "react-hot-toast";
import {QueryClient, QueryClientProvider} from "react-query";
import Header from "./screens/shared/Header";
import {oidcSelector} from "./screens/shared/selectors/oidcSelector";
export const queryClient = new QueryClient()


const RootNavigator = (props) => {

    const oidc = useSelector(oidcSelector);

    return (
        <>
            {oidc?.user && !oidc?.user?.expired && <Header />}
            <Switch>
                <Redirect exact from='/' to='/auth/login' />
                <Route path='/auth' component={Auth} />
                <PrivateRoute path='/dashboard' component={Dashboard} props={props} />
                <PrivateRoute path='/account/:id' component={Account} props={props} />
                <Route path={`/callback`} render={(props) => <CallbackPage />} />
                <Route path={`/silentrenew`} render={(props) => <SilentRenew />} />
            </Switch>
        </>
    )
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inactivityModalIsOpen: false,
            userLoaded: false
        };

        this.idleTimer = null;
        this.closeModal = this.closeModal.bind(this);
        this.checkRequestUpdatedAt = this.checkRequestUpdatedAt.bind(this);
    }

    componentDidMount() {
        let cookies = new Cookies();
        let userLeft = cookies.get('user_last_seen');
        if (moment().diff(moment(parseInt(userLeft)), 'm') >= (window.env.TIMEOUT_IDLE)) {
            let expireTime = moment().add(-1, 'days').unix() * 1000;
            cookies.set('user_last_seen', moment().unix() * 1000, {path: '/', expires: new Date(expireTime)})
            userManager.signoutRedirect();
        }

        this.intervalHandle = setInterval(this.checkRequestUpdatedAt, 1000)
    }

    checkRequestUpdatedAt() {
        let {request, oidc} = this.props.store.getState();
        if ((!oidc.user?.expired ?? true) && (request?.updatedAt)) {
            let lastUpdatedAt = moment(request.updatedAt),
                lastUpdatedAtSeconds = parseInt(moment.duration(moment().diff(moment(lastUpdatedAt))).asSeconds());
            if (lastUpdatedAtSeconds >= ((window.env.TIMEOUT_IDLE - 1) * 60)) {
                this.setState({
                    inactivityModalIsOpen: true
                })
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalHandle);
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
    }

    closeModal() {
        this.props.store.dispatch({type: '@@acuant/TICK'});
        this.setState({
            inactivityModalIsOpen: false
        })
    }

    render() {
        let props = this.props;
        let state = props.store.getState();
        console.log(state.oidc);
        return (
            <QueryClientProvider client={queryClient}>
                <Fragment>
                    <Toaster />
                    {(!state.oidc?.user?.expired ?? true) &&
                        <Fragment>
                            <Modal isOpen={this.state.inactivityModalIsOpen} closeModal={this.closeModal}>
                                <IdleModalComponent closeModal={this.closeModal} idleTimer={this.idleTimer}/>
                            </Modal>
                        </Fragment>
                    }
                    <Provider store={props.store}>
                        <OidcProvider userManager={userManager} store={props.store}>
                            <PersistGate onBeforeLift={() => this.setState({persisted: true})} loading={null} persistor={props.persistor}>
                                <ConnectedRouter history={props.routerHistory}>
                                    <RootNavigator props={props}/>
                                </ConnectedRouter>
                            </PersistGate>
                        </OidcProvider>
                    </Provider>
                </Fragment>
            </QueryClientProvider>
        );
    }
}

export default App;
