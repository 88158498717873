import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import Modal from 'react-modal';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import NewAccountScreen from './Accounts/NewAccountScreen.js';
import EditAccount from './Accounts/EditAccount.js';
import AccountService from "../../services/api/Account";
import AccountParent from "./Accounts/Parent";
import AccountPlaceholder from "./Accounts/Placeholder";
import userHasPermission from './../../utils/checkPermissions';
import InlineMenu from "../shared/InlineMenu";
import * as qs from 'query-string';
import {isEmpty, isEqual, get} from 'lodash';
import {ReactComponent as ClearIcon} from "./../../images/icons/i_clear.svg";

export default class Accounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            editAccountModalIsOpen: false,
            parentAccounts: null,
            initialState: null,
            allAccounts: [],
            sortModalIsOpen: false,
            filterDirection: null,
            selectedAccount: null,
            accountsLoaded: false,
            searchInput: '',
            filteredRow: undefined
        };

        this.onAccountDelete = this.onAccountDelete.bind(this);
        this.getAccounts = this.getAccounts.bind(this);
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    triggerModal() {
        this.setState({
            modalIsOpen: !this.state.modalIsOpen
        })
    }

    triggerEditAccountModal(selectedAccount) {
        this.setState({
            editAccountModalIsOpen: !this.state.editAccountModalIsOpen,
            selectedAccount
        })
    }

    getAccounts() {
        if (userHasPermission(this.props.userClaims, 'AccountViewAccounts')) {

            AccountService.getAccounts()
                .then(accounts => {
                    if (accounts.length) {
                        const queryString = qs.parse(this.props.location.search);
                        if (isEmpty(queryString)) {
                            this.setState({
                                initialState: accounts,
                                accountsLoaded: true,
                                parentAccounts: accounts
                            }, () => {
                                this.filterAccounts(this.state.searchInput);
                            });
                        } else {
                            this.setState({
                                initialState: accounts,
                                accountsLoaded: true
                            });
                        }
                    } else {
                        this.setState({
                            accountsLoaded: true
                        })
                    }
                })
                .catch(err => {
                    throw new Error(err);
                })
            AccountService.getAccounts(-1).then(accounts => {
                this.setState({allAccounts: accounts}, () => {
                    const queryString = qs.parse(this.props.location.search);
                    if (!isEmpty(queryString)) {
                        const row = this.state.allAccounts.filter((account) => account.id === queryString?.account_id);
                        this.setState({
                            ...this.state,
                            parentAccounts: row,
                            filteredRow: get(row, '[0]', undefined)
                        })
                    }
                })
            })
        }
    }

    componentDidMount() {
        this.getAccounts();
    }

    sortAccounts(filterDirection) {
        let accounts = this.state.initialState,
            rootAccount = accounts.filter(account => account.id === this.props.userProfile.accountId)[0];

        accounts = accounts.filter(account => account.id !== this.props.userProfile.accountId)
            .sort((a, b) => a.name.localeCompare(b.name));

        filterDirection === 'ascending' ?
            accounts.unshift(rootAccount) :
            accounts.reverse().unshift(rootAccount);

        this.setState({
            parentAccounts: accounts,
            filterDirection,
            sortModalIsOpen: false
        });
    }

    filterAccounts(value = null) {
        if (!value) {
            this.sortAccounts(this.state.filterDirection || 'ascending')
        } else {
            let parentAccounts = this.state.initialState;
            parentAccounts = parentAccounts.filter(account => account.name.toLowerCase().search(value.toLowerCase()) !== -1);
            this.setState({
                parentAccounts
            });
        }
    }

    onAccountDelete(accountId) {
        let parentAccounts = JSON.parse(JSON.stringify(this.state.parentAccounts)),
            initialState = JSON.parse(JSON.stringify(this.state.initialState));
        parentAccounts = parentAccounts.filter(account => account.id !== accountId);
        initialState = initialState.filter(account => account.id !== accountId);
        this.setState({
            parentAccounts,
            initialState
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.editAccountModalIsOpen !== prevState.editAccountModalIsOpen)|| (this.state.modalIsOpen !== prevState.modalIsOpen) ) {
            if (!this.state.editAccountModalIsOpen || !this.state.modalIsOpen ) {
                this.getAccounts();
            }
        }

        if (this.props.userProfile !== prevProps.userProfile && this.state.initialState) {
            this.sortAccounts('ascending');
        }

        if (this.props.userClaims !== prevProps.userClaims) {
            this.getAccounts();
        }

        if (!isEmpty(this.props.location.search)) {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                const queryString = qs.parse(this.props.location.search);
                const row = this.state.allAccounts.filter((account) => account.id === queryString?.account_id);
                this.setState({
                    ...this.state,
                    parentAccounts: row,
                    filteredRow: get(row, '[0]', undefined)
                })
            }
        } else {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                if (isEmpty(this.props.location.search)) {
                    this.setState({
                        ...this.state,
                        parentAccounts: this.state.initialState,
                        filteredRow: undefined
                    }, () => {
                        this.sortAccounts(this.state.filterDirection || 'ascending')
                    })
                }
            }
        }
    }

    render() {
        let isLoading = (!this.state.parentAccounts || !this.state.initialState || !this.props.userClaims);
        return (
            <section className="accounts">
                <Modal
                    isOpen={this.state.editAccountModalIsOpen}
                    contentLabel="Edit Account"
                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}
                    onRequestClose={() => this.setState({editAccountModalIsOpen: false})}
                >
                    <EditAccount selectedAccount={this.state.selectedAccount} triggerEditAccountModal={this.triggerEditAccountModal.bind(this)}/>

                </Modal>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    contentLabel="Add New Account"

                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}

                    onRequestClose={() => this.setState({modalIsOpen: false})}
                >
                    <NewAccountScreen triggerModal={this.triggerModal.bind(this)}/>

                </Modal>

                <div className="wrapper">

                    <div className="page__head">

                        <h2 className="page__title">Dashboard</h2>

                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['AccountAddAccount']}
                            oneperm
                        >
                            <a href="" className={`cta ${isLoading ? 'is-secondary' : ''}`} onClick={(e) => {e.preventDefault(); !isLoading && this.triggerModal()}} >
                                <span className="mobile-only">+ Add new</span>
                                <span className="tablet-and-above">+ Add new account</span>
                            </a>
                        </PermissibleRender>

                    </div>


                        <div className="page__sub-head">

                            <InlineMenu menuIsOpen={this.state.sortModalIsOpen} closeMenu={() => this.setState({sortModalIsOpen: !this.state.sortModalIsOpen})} className="filter__container">
                                <a onClick={() => this.setState({sortModalIsOpen: !this.state.sortModalIsOpen})} className={`filter__trigger ${this.state.sortModalIsOpen ? 'is-active' : ''}`}>Sort By</a>
                                <div className="filter__content inline-menu arrow center top">
                                    <ul className="filter">
                                        <li><a onClick={this.sortAccounts.bind(this, 'ascending')} className={`filter-item ${this.state.filterDirection === 'ascending' ? 'is-selected' : ''}`}>A-Z</a></li>
                                        <li><a onClick={this.sortAccounts.bind(this, 'descending')} className={`filter-item ${this.state.filterDirection === 'descending' ? 'is-selected' : ''}`}>Z-A</a></li>
                                    </ul>
                                </div>
                            </InlineMenu>

                            <div className="">
                                <div className="">
                                    {this.state.filteredRow && (
                                        <div className={'px-4 py-2 bg-white border text-md flex flex-row items-center justify-center'}>
                                            <div className={'pr-2 text-gray-500'}>{this.state.filteredRow.name}</div>
                                            <ClearIcon className={'cursor-pointer'} onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push('/dashboard/accounts')
                                            }}/>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="account__list">

                            <div className="account__head">

                                <div className="account__row">
                                    <div className="account__cell alpha">
                                    </div>
                                    <div className="account__cell beta">Account Name</div>
                                    <div className="account__cell gamma">Child Accounts</div>
                                    <div className="account__cell delta">Subscriptions</div>
                                    <PermissibleRender
                                        userPermissions={this.props.userClaims}
                                        requiredPermissions={['AccountEditAccount']}
                                        oneperm
                                    >

                                        <div className="account__cell epsilon">Actions</div>
                                    </PermissibleRender>
                                </div>
                            </div>
                            {isLoading && <AccountPlaceholder count={12}/>}

                            {this.state.parentAccounts?.map(account => {
                                return <AccountParent getAccounts={this.getAccounts} onAccountDelete={this.onAccountDelete} userClaims={this.props.userClaims} userProfile={this.props.userProfile} triggerEditAccountModal={this.triggerEditAccountModal.bind(this)} history={this.props.history} key={account.id + account.name} account={account}/>
                            })}

                        </div>

                </div>
            </section>

        );
    }
}