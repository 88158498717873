import React, {Component} from 'react';
import CustomModal from "./Modal";

export default class DeleteModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {data, isOpen, closeModal, onDelete, error, errorMessage} = this.props;
        return (
            <CustomModal height={'20%'} isOpen={isOpen} closeModal={() => closeModal()}>
                {data &&
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
                    <div style={{display: 'flex', color: 'rgba(0, 0, 0, 0.8)', fontSize: 18, lineHeight: 'normal', fontWeight: 700, height: '25%', alignItems: 'flex-end', textAlign: 'center'}}>Are you sure you want to delete "{data}"?</div>
                    <div style={{height: '25%', width: '100%', justifyContent: 'space-around', display: 'flex'}}>
                        <a onClick={e => {e.preventDefault(); closeModal()}} href="#" className="cta is-small is-secondary"><span className="tablet-and-above">Cancel</span></a>
                        <a onClick={e => {e.preventDefault(); onDelete()}} href="#" className="cta is-small"><span className="tablet-and-above">Delete</span></a>
                    </div>
                    {error && <div style={{height: '25%', alignItems: 'center', justifyContent: 'center', display: 'flex', textAlign: 'center'}}>
                        <span style={{color: "#D52B1E"}}>{errorMessage}</span>
                    </div>}

                </div>
                }
            </CustomModal>
        );
    }
}