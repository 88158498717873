import request from '../shared/request';

function getRoles() {
    return request({
        url: '/api/roles'
    });
}

function getRoleById(id) {
    return request({
        url: `/api/roles/${id}`
    });
}

function updateRole(id, data) {
    return request({
        url: `/api/roles/${id}`,
        method: 'PUT',
        data: data
    })
}

function createRole(data) {
    return request({
        url: `/api/roles/`,
        method: 'POST',
        data: data
    })
}

const RolesService = {
    getRoles,
    getRoleById,
    updateRole,
    createRole
};

export default RolesService;