import React, {Fragment} from 'react';
import {Field} from "redux-form";
import SwitchButton from "lyef-switch-button";

export const RadioButtons = ({ fieldName, options, parentClassName }) => (
    <div className={parentClassName}>
        <Field
            component={({input, options}) => (
                options && options.map(option => (
                    <div className="context child" key={option.id}>
                        <div className="context__cell alpha">
                            <span className="context__title">{option.label}</span>
                        </div>
                        <div className="context__cell beta">
                            <input
                                disabled={option.isDisabled || options.disabled}
                                className={option.className || ''}
                                id={option.id}
                                type='radio'
                                {...input}
                                value={!isNaN(option.value) ? parseInt(option.value) : option.value}
                                checked={!isNaN(option.value) ? parseInt(option.value) === parseInt(input.value) : option.value === input.value}
                            />
                        </div>
                </div>
                ))
            )}
            name={fieldName}
            options={options}
        />
    </div>
);
