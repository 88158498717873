import React, {Component} from 'react';
import Modal from 'react-modal';
Modal.setAppElement('#root');

export default class CustomModal extends Component {
    render() {
        let {isOpen, closeModal, height, width} = this.props;
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={() => {
                    closeModal()
                }}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        zIndex: 100
                    },
                    content : {
                        top                   : '50%',
                        left                  : '50%',
                        right                 : 'auto',
                        bottom                : 'auto',
                        marginRight           : '-50%',
                        width: width || '25%',
                        minHeight: height || '35%',
                        transform             : 'translate(-50%, -50%)',
                        display: 'flex'
                    }
                }}
                contentLabel="Example Modal"
            >
                {this.props.children}
            </Modal>
        );
    }
}