import React, {Component, Fragment} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form';
import {InputField} from "../../../inputs/standard";
import {passwordComplexity, passwordsMatch, required} from "../../../inputs/validations";
import Tooltip from 'rc-tooltip';
import RolesService from "../../../services/api/Roles";
import UserService from "../../../services/api/Users";
import {connect} from "react-redux";
import SubscriptionAssignModal from "./SubscriptionAssignModal";
import SubscriptionMobileAssignModal from "./SubscriptionMobileAssignModal";
import QRCode from "qrcode.react";
import authenticator from "authenticator";
import {queryClient} from "../../../App";
import PasswordRules from "../../../components/PasswordRules";

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetails: {
                roles: [],
                subscriptions: []
            },
            mappedRoles: new Map(),
            subscriptionAssignOpen: false,
            subscriptionAssignOpenMobile: false,
            mfaEnabled: window.env.MFA_REQUIRED,
            showMFAError: false,
            showMFARequiredError: false,
            otpAuthURI: null,
            mfaSecret: null
        };

        this.setMFASecret = this.setMFASecret.bind(this);
    }

    componentDidMount() {
        this.getUserRoles();
        this.setMFASecret()
    }

    generateRandomString() {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        array = array.map(x => validChars.charCodeAt(x % validChars.length));
        return String.fromCharCode.apply(null, array);
    }

    setMFASecret() {
        if (this.props.formValues && this.props.formValues.name) {
            let secretKey = this.generateRandomString();
            this.setState({
                mfaSecret: secretKey.replace(/\W/g, '').toLowerCase(),
                showMFAError: false,
                showMFARequiredError: false
            })
            let otpAuthURI = authenticator.generateTotpUri(secretKey, this.props.formValues.name, "AcuantAdminPortal", "SHA256", 6, 30);
            console.log(otpAuthURI);
            this.setState({
                otpAuthURI
            })
        }
    }

    removeSubscription(id) {
        let subscriptions = this.state.userDetails.subscriptions;
        subscriptions = subscriptions.filter(subscription => subscription.id !== id);
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                subscriptions
            }
        }, () => {
            this.props.change('subscriptions', subscriptions);
        });
    }

    getUserRoles() {
        RolesService.getRoles()
            .then(roles => {
                let mappedRoles = new Map();
                roles.map(role => {
                    mappedRoles.set(role, false);
                });
                this.setState({
                    roles,
                    mappedRoles
                })
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    setMappedRoles() {
        let mappedRoles = new Map();
        this.state.roles.map(role => {
            mappedRoles.set(role, false);
            this.state.userDetails.roles.map(r => {
                if (r.id === role.id) {
                    mappedRoles.set(role, true);
                }
            });
        });
        this.setState({
            mappedRoles
        })
    }

    onRoleChange(event, role) {
        console.log(event.target.checked);
        if (event.target.checked) {
            let roles = this.state.userDetails.roles;
            roles.push(role);
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    roles
                }
            }, () => {
                this.props.change('roles', this.state.userDetails.roles);
                this.setMappedRoles();
            })
        } else {
            let roles = this.state.userDetails.roles;
            roles = roles.filter(r => r.id !== role.id);
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    roles
                }
            }, () => {
                this.props.change('roles', this.state.userDetails.roles);
                this.setMappedRoles();
            })
        }
    }

    onSubmit() {
        let {formValues} = this.props;
        formValues = {...formValues, accountId: this.props.account.id, isEnabled: true};

        formValues.isMFAEnabled = this.state.mfaEnabled;


        return UserService.createUser(formValues)
            .then(user => {
                formValues = {...formValues, id: user.id};
                UserService.editUser(formValues, user.id)
                    .then(() => {
                        queryClient.refetchQueries(['users']);
                        if (this.state.mfaEnabled) {
                            UserService.editMFASecret({secret: this.state.mfaSecret, id: formValues.id}, formValues.id)
                                .then(resp => {
                                    this.props.triggerAddUserModal()
                                })
                                .catch(err => {
                                    console.error(err);
                                })
                        } else {
                            this.props.triggerAddUserModal()
                        }
                    })
                    .catch(err => {
                        throw new Error(err);
                    })
            })
            .catch(err => {
                throw new SubmissionError({...err.data});
            })
    }

    onSubscriptionsSelect(subscriptions) {
        this.setState({
            subscriptions,
            subscriptionAssignOpen: false,
            subscriptionAssignOpenMobile: false
        }, () => {
            this.props.change('subscriptions', subscriptions);
        })
    }

    onMFAChange(e) {
        this.setState({
            mfaEnabled: e.target.checked
        });
        if (e.target.checked) {
            console.log(this.state.userDetails);
            this.setMFASecret()
        } else {
            if (window.env.MFA_REQUIRED) {
                this.setState({
                    showMFAError: false,
                    showMFARequiredError: true
                })
            }

            this.setState({
                showMFAError: true
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formValues && this.props.formValues && prevProps.formValues.name !== this.props.formValues.name) {
            if (this.state.mfaEnabled) {
                this.setMFASecret()
            }
        }
    }

    render() {
        let roles = [];
        this.state.mappedRoles.forEach((value, role) => {
            roles.push(
                <li key={role.id}>
                    <input checked={value} onChange={(e) => this.onRoleChange(e, role)} className="checkbox" type="checkbox" name={`role-${role.id}`} id={`role-${role.id}`}/>
                    <label htmlFor={`role-${role.id}`}>{role.name}</label>
                    <Tooltip placement="left" trigger="click" destroyTooltipOnHide={true} placement="right" overlay={role.description}>
                        <a href="#" className="tooltip-trigger info"></a>
                    </Tooltip>
                </li>
            )
        });
        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">Create new user</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerAddUserModal()}}></span>
                </div>
                <div className="e-modal__body">
                    <div className="f-container">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-name">Name</label>
                                        <Field
                                            component={InputField}
                                            validate={[required]}
                                            className={'field'}
                                            type="text"
                                            name="fullName"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-email">Username</label>
                                        <Field
                                            component={InputField}
                                            validate={[required]}
                                            className={'field'}
                                            type="text"
                                            name="name"
                                            placeholder="Enter username"
                                            alt="Enter email"
                                            tabIndex="2"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-description">Password</label>
                                        <Field
                                            component={InputField}
                                            validate={[required, passwordComplexity(), passwordsMatch()]}
                                            className={'field'}
                                            type="password"
                                            name="password"
                                            placeholder="Enter user password"
                                            alt="Enter user password"
                                            tabIndex="3"
                                        />
                                        <PasswordRules password={this.props.formValues?.password}/>
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-description">Confirm password</label>
                                        <Field
                                            component={InputField}
                                            validate={[required, passwordsMatch()]}
                                            className={'field'}
                                            type="password"
                                            name="confirm_password"
                                            placeholder="Confirm user password"
                                            alt="Confirm user password"
                                            tabIndex="3"
                                        />
                                    </div>
                                </div>
                                <div className="f-row border-top">
                                    <h2 className="f-section__title ">Enable MFA</h2>
                                    <ul className="add-role-list">
                                        <li>
                                            <input checked={this.state.mfaEnabled} onChange={(e) => this.onMFAChange(e)} className="checkbox" type="checkbox" name={`mfa-enabled`} id={`mfa-enabled`}/>
                                            <label htmlFor={`mfa-enabled`}>MFA Enabled</label>
                                        </li>
                                        {this.state.showMFARequiredError && <div className={'f-element'}>
                                            <p className="help is-danger">MFA is required to log into this system. If you disable MFA for this user, they will be unable to log in.</p>
                                        </div>
                                        }
                                        {this.state.showMFAError && <div className={'f-element'}>
                                            <p className="help is-danger">If you disable MFA and later re-enable it, you will need to rescan the QR Code</p>
                                        </div>
                                        }
                                    </ul>

                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                        {this.state.mfaEnabled && this.state.otpAuthURI &&
                                        <Fragment>
                                            <QRCode value={this.state.otpAuthURI} />
                                            <div style={{marginTop: 8}}>This code must be scanned using Google Authenticator on an iPhone only.</div>
                                        </Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="f-row border-top">
                                    <h2 className="f-section__title ">User role</h2>
                                    <ul className="add-role-list">
                                        {roles}
                                    </ul>
                                </div>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Assign to subscription</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <a className={`context__trigger asign-new-sub__trigger ${this.state.subscriptionAssignOpen ? 'is-active' : ''}`}>
                                                <span onClick={() => this.setState({subscriptionAssignOpenMobile: !this.state.subscriptionAssignOpenMobile})} className="mobile-only">+ Assign new</span>
                                                <span onClick={() => this.setState({subscriptionAssignOpen: !this.state.subscriptionAssignOpen})} className="tablet-and-above">+ Assign new subscription</span>
                                            </a>
                                            {/* TABLET AND ABOVE */}
                                            {this.state.subscriptionAssignOpen && <SubscriptionAssignModal selectedSubscriptions={this.state.userDetails.subscriptions} onSubscriptionsSelect={this.onSubscriptionsSelect.bind(this)} accountId={this.props.account.id} />}
                                        </div>

                                        {this.state.subscriptionAssignOpenMobile && <SubscriptionMobileAssignModal selectedSubscriptions={this.state.userDetails.subscriptions} onSubscriptionsSelect={this.onSubscriptionsSelect.bind(this)} accountId={this.props.account.id}/> }

                                    </div>

                                    <div className="e-table">
                                        {this.state.userDetails.subscriptions && this.state.userDetails.subscriptions.length > 0 && this.state.userDetails.subscriptions.map(subscription => {
                                            return (
                                                <div key={subscription.id} className="e-table__row">
                                                    <div className="e-table__cell alpha">
                                                        <span className="e-table__name">{subscription.name}</span>
                                                        <span className="e-table__data">ID: {subscription.id}</span>
                                                    </div>
                                                    <div className="e-table__cell beta">
                                                        <span className="e-table__data bold uppercase tiny vertically-centered">{subscription.accountName}</span>
                                                    </div>
                                                    <div className="e-table__cell control_cell">
                                                        <a onClick={e => {e.preventDefault(); this.removeSubscription(subscription.id)}}>Remove</a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row cta-container">

                                    <div className="f-element alpha">
                                        <button className={'cta'}>Add new user</button>
                                    </div>

                                    <div className="f-element beta">
                                        <button onClick={(e) => {e.preventDefault(); this.props.triggerAddUserModal()}} className="light">Cancel</button>
                                    </div>

                                </div>

                            </div>

                        </form>

                    </div>

                </div>

            </div>

        );
    }
}

AddUser = reduxForm({
    form: 'addUser'
})(AddUser);

function mapStateToProps(state) {
    const selector = formValueSelector('addUser');
    return {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        formValues: getFormValues('addUser')(state),
        subscriptions: selector(state, 'subscriptions')
    }
}

export default connect(mapStateToProps)(AddUser);