import React, {Component} from 'react';
import RolesService from "../../../services/api/Roles";
import RoleCategory from "./RoleCategory";
import {connect} from "react-redux";


class RoleItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            roleDetails: null,
            roleId: null,
            mappedRoleDetails: new Map()
        }
    }

    getRoleDetails() {
        RolesService.getRoleById(this.props.role.id)
            .then(roleDetails => {
                let mappedRoleDetails = new Map();
                this.props.claims.map(claim => {
                    mappedRoleDetails.set(claim, false);
                });
                for (var key of mappedRoleDetails.keys()) {
                    roleDetails.claims.map(claim => {
                        if (claim.id === key.id) {
                            mappedRoleDetails.set(key, true);
                        }
                    })
                }
                this.setState({
                    roleDetails,
                    mappedRoleDetails,
                    roleId: roleDetails.id
                });
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    changeRoleValue(mappedRoleDetails) {
        let claims = [];
        mappedRoleDetails.forEach((value, claim) => {
            if (value) {
                claims.push(claim);
            }
        });
        this.setState({
            mappedRoleDetails,
            roleDetails: {
                ...this.state.roleDetails,
                claims: claims
            }
        }, () => {
            RolesService.updateRole(this.props.role.id, this.state.roleDetails)
                .then(result => {
                    console.log(result)
                })
                .catch(err => {
                    //throw new Error(err);
                })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isExpanded !== prevState.isExpanded) {
            if (this.state.isExpanded === true) {
                this.getRoleDetails();
            }
        }
    }

    render() {
        if (!this.props.role) return null;
        let {role} = this.props;
        return (
            <div className="entity-list__item role-item">

                <div className={`role__head ${this.state.isExpanded ? 'is-active' : ''}`}>

                    <div className="role__row">

                        <div className="role__cell alpha">
                            <div className="role-type__icon user"></div>
                        </div>

                        <div className="role__cell beta">
                            <div className="role-type__name">{role.name}</div>
                        </div>

                        <div className="role__cell gamma">
                            <div className="role-type__description">{role.description}</div>
                        </div>

                        <div className="role__cell delta">
                            <a
                                onClick={() => this.setState({isExpanded: !this.state.isExpanded})}
                                className={`role-type__expand ${this.state.isExpanded ? 'is-active' : ''}`}>
                                    <span className="role-type__open">
                                        <span className="mobile-only">Show</span>
                                        <span className="tablet-and-above">Show Permissions</span>
                                    </span>
                                    <span className="role-type__close">
                                        <span className="mobile-only">Hide</span>
                                        <span className="tablet-and-above">Hide Permissions</span>
                                    </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="role__body">
                    <div className="permission-list">
                        {this.props.claimsByCategory.map((claimCategory, index) => {
                            return <RoleCategory roleId={this.state.roleId} key={claimCategory.id} claimCategory={claimCategory} changeRoleValue={this.changeRoleValue.bind(this)} mappedRoleDetails={this.state.mappedRoleDetails}/>
                        })}
                    </div>

                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        claims: state.config.claims
    }
}

export default connect(mapStateToProps)(RoleItem);