import {addValidator} from 'redux-form-validators';
import moment from "moment";

export const required = value => (value ? undefined : 'This field is required.');

export const validateUniqueCustomerID = addValidator({
    validator: (options, value, allValues) => {
        if (options.accounts && options.initialValue) {
            if (options.accounts.filter(a => a.customerId === value).length && value !== options.initialValue){
                return {
                    defaultMessage: 'CustomerID already exists.'
                }
            }
        }
    }
})

export const passwordsMatch = addValidator({
    validator: (options, value, allValues) => {
        if (allValues?.password?.length > 0 || allValues?.confirm_password?.length > 0) {
            if (allValues?.password !== allValues?.confirm_password) {
                return {
                    defaultMessage: 'Passwords do not match.'
                }
            }
        }
        if (allValues.password && allValues.password.length > 0
            && allValues.confirm_password && allValues.confirm_password.length > 0) {
            if (allValues.password !== allValues.confirm_password) {
                return {
                    defaultMessage: 'Passwords do not match.'
                }
            }
        }
    }
});

export const expirationAfterActivationDate = addValidator({
    validator: (options, value, allValues) => {
        if (!value instanceof moment) return undefined;
        if (!allValues.activationDate instanceof moment) return undefined;
        if (moment(value).isBefore(allValues.activationDate)) {
            return {
                defaultMessage: 'Expiration date must be one day after activation date.'
            }
        }
    }
});

export const endAfterBeginDate = addValidator({
    validator: (options, value, allValues) => {
        if (!value instanceof moment) return undefined;
        if (!allValues.begin instanceof moment) return undefined;
        if (moment(value).isBefore(moment(allValues.begin).add(1, 'days'))) {
            return {
                defaultMessage: 'End date must be one day after start date.'
            }
        }
    }
});

export const isValidDate = addValidator({
    validator: (options, value, allValues) => {
        if (!value.toString().length) {
            return {
                defaultMessage: 'Field must be a valid date'
            }
        }
    }
});

export const passwordComplexity = addValidator({
    validator: (options, value, allValues) => {
        if (value) {
            if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]).{12,}$/.test(value)) {
                return {
                    defaultMessage: 'Password should be at least 12 characters. It should include at least 1 number, 1 uppercase and 1 special character.'
                }
            }
        }
    }
});