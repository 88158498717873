import React, {Component} from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "connected-react-router";
import userManager from "./../../utils/userManager";
import Cookies from 'universal-cookie';
import ProfileService from "../../services/api/Profile";
import jwtDecode from 'jwt-decode';
import {bindActionCreators} from "redux";
import {populateUser} from "./actions";
import {populateClaims, populateCurrentUserClaims} from "../shared/actions";
import ClaimsService from "../../services/api/Claims";

class CallbackPage extends Component {

    constructor(props) {
        super(props);
    }

    successCallback = (user) => {
        const cookies = new Cookies();
        cookies.set('token', user.access_token, { path: '/', expires: new Date(user.expires_at * 1000)});
        var decoded = jwtDecode(user.access_token);
        if (decoded && decoded.profile) {
            ClaimsService.getClaims()
                .then(claims => {
                    this.props.populateClaims(claims);
                    this.props.populateCurrentUserClaims(decoded.profile)
                })
                .catch(err => {
                    throw new Error(err);
                });
        }
        ProfileService.getProfile()
            .then(profile => {
                this.props.populateUser(profile);
            })
            .catch(err => {
                userManager.logout();
            });
        this.props.dispatch(push("/dashboard/accounts"));
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.successCallback.bind(this)}
                errorCallback={error => {
                    this.props.dispatch(push("/"));
                    console.error(error);
                }}
            >
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({populateUser, populateCurrentUserClaims, populateClaims}, dispatch);
    return {...actions, dispatch};
}

export default connect(null, mapDispatchToProps)(CallbackPage);
