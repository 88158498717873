import React, {Component, Fragment} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form';
import SwitchButton from 'lyef-switch-button';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {DayPicker} from "../../../inputs/dayPicker";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SubscriptionService from "../../../services/api/Subscriptions";
import moment from "moment";
import {InputField} from "../../../inputs/standard";
import {expirationAfterActivationDate, required} from "../../../inputs/validations";
import {SelectInput} from "../../../inputs/select";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import userHasPermission from "../../../utils/checkPermissions";
import {RadioButtons} from "../../../inputs/radioButtons";
import checkPermissions from "../../../utils/checkPermissions";
import {queryClient} from "../../../App";

class CreateSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productTypes: [
                {label: 'Data Capture', value: 1},
                {label: 'Authentication', value: 2},
                {label: 'Barcode', value: 3}
            ],
            ttlOptions: [
                {
                    label: "Hourly",
                    value: "hourly",
                },
                {
                    label: "Daily",
                    value: "daily",
                },
                {
                    label: "Weekly",
                    value: "weekly",
                },

            ],
            products: {
                assureIdEnhanced: false,
                assureIdLite: false,
                loqate: false,
                enhancedTampering: false,
                shareDiagnostics: false,
                facialMatch: '',
                facialLiveness: '',
                review: 'false',
                ozone: false,
                chipExtraction: false
            },
            subscriptionTypes: [{label: 'Production', value: 'Production'}, {label: 'Development', value: 'Development'}]
        }
    }

    componentDidMount() {
        this.props.initialize({
            description: {},
            storePii: false,
            authorizationScheme: 'Basic'
        })
    }

    onSubmit(values) {
        let formValues = JSON.parse(JSON.stringify(values));
        formValues.licenseTypeName = formValues.licenseTypeName.value;
        formValues.documentProcessMode = formValues.documentProcessMode ?? 0;

        formValues.description = JSON.stringify(formValues.description);
        formValues.jwksUrl = formValues?.jwksUrl ?? null
        formValues.jwksTtl = formValues.jwksTtl?.value ?? null
        formValues = {...formValues, accountId: this.props.accountId};
        return SubscriptionService.createSubscription(formValues)
            .then(response => {
                queryClient.refetchQueries(['subscriptions']);
                if (userHasPermission(this.props.userClaims, ['IsAcuant', 'IsCloud'])) {
                    let authorizationObject = {
                        'passiveLiveness': formValues['facialLiveness'] === 'passiveLiveness',
                        'facialMatch': formValues['facialMatch'] || '',
                        'ozone': this.state.products.ozone,
                        'mobile/features/chipExtract': this.state.products.chipExtraction,
                        'review': this.state.products.review,
                        'assureid': !!formValues.documentProcessMode ?? false,
                        'assureid/features/enhancedOcr': this.state.products.assureIdEnhanced,
                        'assureid/features/lite': this.state.products.assureIdLite,
                        'assureid/features/loqate': this.state.products.loqate,
                        'assureid/features/enhancedTampering': this.state.products.enhancedTampering,
                        'assureid/features/shareDiagnostics': this.state.products.shareDiagnostics
                    }
                    SubscriptionService.updateAuthorization(response.id, JSON.stringify(JSON.stringify(authorizationObject)))
                        .then(response => {
                            this.props.triggerCreateSubModal()
                        })
                        .catch(err => {
                            throw new Error(err);
                        })
                } else {
                    this.props.triggerCreateSubModal()
                }
            })
            .catch(err => {
                throw new SubmissionError({...err.data});
            })
    }

    render() {
        if (!this.props.formValues) return null;

        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">Create Subscription</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerCreateSubModal()}}></span>
                </div>
                <div className="e-modal__body">
                    <div className="f-container create-subscription">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="subscription-name">Subscription Name</label>
                                        <Field
                                            component={InputField}
                                            type="text"
                                            validate={[required]}
                                            name="name"
                                            className='field'
                                            placeholder="Enter subscription name"
                                            alt="Enter subscription name"
                                            tabIndex="1"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="subscription-type">Subscription Type</label>
                                        <Field
                                            name="licenseTypeName"
                                            component={SelectInput}
                                            tabIndex="4"
                                            options={this.state.subscriptionTypes}
                                            validate={[required]}
                                            onChange={(event, newValue) => {
                                                //event.preventDefault();
                                                if (newValue.value === 'Production') {
                                                    this.props.change('licenseType', 1);
                                                } else {
                                                    this.props.change('licenseType', 2);
                                                }
                                                this.props.change('licenseTypeName', newValue);
                                            }}
                                        />
                                        <Field component={InputField} type='hidden' name={'licenseType'}/>
                                    </div>
                                </div>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['IsCloud']}
                                >
                                    <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Enable public key client authentication</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`authorizationScheme`}
                                                isChecked={this.props?.formValues?.authorizationScheme === "Bearer" ?? false}
                                                action={() => {
                                                    if (checkPermissions(this.props.userClaims, ['IsCloud', 'IsAcuant'])) {
                                                        if (this.props.formValues['authorizationScheme'] === 'Basic') {
                                                            this.props.change('authorizationScheme', "Bearer");
                                                            if (!this.props.formValues.jwksTtl?.value) {
                                                                setTimeout(() => {
                                                                    this.props.change('jwksTtl', {
                                                                        label: 'Hourly',
                                                                        value: 'hourly'
                                                                    })
                                                                }, 100)
                                                            }
                                                        } else {
                                                            this.props.change('authorizationScheme', "Basic");
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {(this.props?.formValues?.authorizationScheme ?? "Basic") !== 'Basic' &&
                                        <Fragment>
                                            <div className={'f-row'} style={{width: '100%'}}>
                                                <div className="f-element">
                                                    <label htmlFor="subscription-type">Configure a jwks url to enable public key authorization mechanisms</label>
                                                    <Field component={InputField}
                                                           type="text"
                                                           validate={[required]}
                                                           className='field'
                                                           placeholder="Enter JWKS URL"
                                                           alt="Enter JWKS URL"
                                                           tabIndex="1"
                                                           fieldName={'jwksUrl'}
                                                           name={'jwksUrl'}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'f-row'} style={{width: '100%'}}>
                                                <div className="f-element">
                                                    <label htmlFor="subscription-type">Time to live (Poll time to check for new certificates)</label>
                                                    <Field component={SelectInput}
                                                           fieldName={'jwksTtl'}
                                                           validate={[required]}
                                                           style={{width: '100%'}}
                                                           name={'jwksTtl'}
                                                           options={this.state.ttlOptions}
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                </PermissibleRender>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Assure ID</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`assureid`}
                                                isChecked={!!this.props.formValues['documentProcessMode']}
                                                action={() => {
                                                    if (checkPermissions(this.props.userClaims, ['IsAcuant'])) {
                                                        if (!this.props.formValues['documentProcessMode']) {
                                                            this.props.change('documentProcessMode', 1);
                                                        } else {
                                                            this.props.change('documentProcessMode', 0);
                                                            this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdEnhanced: false
                                                                }
                                                            })
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PermissibleRender
                                        userPermissions={this.props.userClaims}
                                        requiredPermissions={['IsCloud']}
                                        oneperm
                                    >
                                        <Fragment>
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">AssureID Enhanced</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`assureid_enhanced`}
                                                            isChecked={this.state.products.assureIdEnhanced}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdEnhanced: !this.state.products.assureIdEnhanced
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">AssureID Lite</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`assureid_lite`}
                                                            isChecked={this.state.products.assureIdLite}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdLite: !this.state.products.assureIdLite
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Loqate Address Services</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`loqate`}
                                                            isChecked={this.state.products.loqate}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    loqate: !this.state.products.loqate
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Enhanced Tampering</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`enhancedTampering`}
                                                            isChecked={this.state.products.enhancedTampering}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    enhancedTampering: !this.state.products.enhancedTampering
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Share diagnostic data (including PII)</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`shareDiagnostics`}
                                                            isChecked={this.state.products.shareDiagnostics}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    shareDiagnostics: !this.state.products.shareDiagnostics
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    </PermissibleRender>
                                    {this.props.formValues['documentProcessMode'] > 0 &&
                                        <Field component={RadioButtons}
                                               style={{width: '100%'}}
                                               parentClassName={'custom-radio__container'}
                                               fieldName={'documentProcessMode'}
                                               name={'documentProcessMode'}
                                               options={
                                                   [
                                                       {
                                                           id: "datacapture",
                                                           label: "Data Capture",
                                                           value: 1,
                                                       },
                                                       {
                                                           id: "authentication",
                                                           label: "Authentication",
                                                           value: 2,
                                                       },
                                                       {
                                                           id: "barcode",
                                                           label: "Barcode",
                                                           value: 3,
                                                       },

                                                   ]
                                               }
                                        />
                                    }
                                </div>
                                <div className="f-row border-top" style={{display: 'none'}}>
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title large">Assign users to this subscription</span>
                                        </div>
                                        <div className="context__cell beta inline-menu__container">
                                            <a className="context__trigger inline-menu__trigger">+ Add User</a>
                                            <div className="inline-menu tablet-and-above arrow top right">
                                                <div className="aum">
                                                    <h3 className="aum__title">Assign new user</h3>
                                                    <ul className="aum__list">
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">John Doe</span>
                                                                <span className="aum__email">admin@assureid.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-one" id="select-one" />
                                                                <label htmlFor="select-one"></label>
                                                            </div>
                                                        </li>
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">Tim Lucia</span>
                                                                <span className="aum__email">admin@tlucia.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-two" id="select-two" />
                                                                <label htmlFor="select-two"></label>
                                                            </div>
                                                        </li>
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">Anthony Barngrazi</span>
                                                                <span className="aum__email">abarngrazi@acuantcorp.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-three" id="select-three" />
                                                                <label htmlFor="select-three"></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <button>Assign selected users (3)</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aum mobile">
                                        <h3 className="aum__title">Assign new user</h3>
                                        <ul className="aum__list">
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">John Doe</span>
                                                    <span className="aum__email">admin@assureid.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-one" id="select-one" />
                                                    <label htmlFor="select-one"></label>
                                                </div>
                                            </li>
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">Tim Lucia</span>
                                                    <span className="aum__email">admin@tlucia.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-two" id="select-two" />
                                                    <label htmlFor="select-two"></label>
                                                </div>
                                            </li>
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">Anthony Barngrazi</span>
                                                    <span className="aum__email">abarngrazi@acuantcorp.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-three" id="select-three" />
                                                    <label htmlFor="select-three"></label>
                                                </div>
                                            </li>
                                        </ul>
                                        <button>Assign selected users (3)</button>
                                    </div>
                                    <div className="e-table">
                                        <div className="e-table__row">
                                            <div className="e-table__cell">
                                                <span className="e-table__data large">No users added yet.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="f-row border-top">
                                    <div className="context">

                                        <div className="context__cell alpha">
                                            <span className="context__title">Store Personally Identifiable Information (PII)</span>
                                        </div>

                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`store-pii`}
                                                isChecked={this.props.formValues.storePii}
                                                action={() => this.props.change('storePii', !this.props.formValues.storePii)}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['IsAcuant', 'IsCloud']}
                                >
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Facial Match</span>
                                            </div>
                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`facialMatch`}
                                                    isChecked={!!this.props.formValues['facialMatch']}
                                                    action={() => {
                                                        if (!this.props.formValues['facialMatch']) {
                                                            this.props.change('facialMatch', 'social');
                                                        } else {
                                                            this.props.change('facialMatch', '');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.props.formValues['facialMatch'] &&
                                            <Field component={RadioButtons}
                                                   style={{width: '100%'}}
                                                   parentClassName={'custom-radio__container'}
                                                   fieldName={'facialMatch'}
                                                   name={'facialMatch'}
                                                   options={
                                                       [
                                                           {
                                                               id: "social",
                                                               label: "Face Standard",
                                                               value: "social",
                                                           },
                                                           {
                                                               id: "nist",
                                                               label: "Face Government",
                                                               value: "nist",
                                                           },
                                                           {
                                                               id: "enhanced",
                                                               label: "Face Enhanced",
                                                               value: "enhanced",
                                                           },
                                                       ]
                                                   }/>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Facial Liveness</span>
                                            </div>
                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`facialLiveness`}
                                                    isChecked={this.props.formValues['facialLiveness']}
                                                    action={() => {
                                                        if (!this.props.formValues['facialLiveness']) {
                                                            this.props.change('facialLiveness', 'passiveLiveness');
                                                        } else {
                                                            this.props.change('facialLiveness', '');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.props.formValues['facialLiveness'] &&
                                        <Field component={RadioButtons}
                                               style={{width: '100%'}}
                                               parentClassName={'custom-radio__container'}
                                               fieldName={'facialLiveness'}
                                               name={'facialLiveness'}
                                               options={
                                                   [
                                                       {
                                                           id: "passiveLiveness",
                                                           label: "Passive Liveness",
                                                           value: "passiveLiveness",
                                                       }
                                                   ]
                                               }/>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Review</span>
                                            </div>

                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`review`}
                                                    isChecked={(this.state.products.review === 'true' || this.state.products.review === 'mock')}
                                                    action={() => this.setState({
                                                        products: {
                                                            ...this.state.products,
                                                            review: (this.state.products.review === 'true' || this.state.products.review === 'mock') ? 'false' : 'true'
                                                        }
                                                    })}
                                                />
                                            </div>

                                        </div>
                                        {(this.state.products?.review === 'true' || this.state.products?.review === 'mock') &&
                                            <div className="context child">
                                                <div
                                                    className="context__cell alpha">
                                                        <span
                                                            className="context__title">Mock Response Mode</span>
                                                </div>

                                                <div
                                                    className="context__cell beta">
                                                    <SwitchButton
                                                        id={`mock`}
                                                        isChecked={this.state.products.review === 'mock'}
                                                        action={() => this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                review: (this.state.products.review === 'mock') ? 'true' : 'mock'
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Ozone</span>
                                            </div>

                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`ozone`}
                                                    isChecked={this.state.products.ozone}
                                                    action={() => {
                                                        this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                ozone: !this.state.products.ozone,
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.state.products.ozone &&
                                            <div className="context child">
                                                <div className="context__cell alpha">
                                                    <span className="context__title">Mobile Extraction Only</span>
                                                </div>
                                                <div className="context__cell beta">
                                                    <SwitchButton
                                                        id={`chipExtraction`}
                                                        isChecked={this.state.products.chipExtraction}
                                                        action={() => this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                chipExtraction: !this.state.products.chipExtraction
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </PermissibleRender>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row cta-container">
                                    <div className="f-element alpha">
                                        <button className={'cta'}>Create subscription</button>
                                    </div>
                                    <div className="f-element beta">
                                        <button onClick={(e) => {e.preventDefault(); this.props.triggerCreateSubModal()}} className="light">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

CreateSubscription = reduxForm({
    form: 'createSubscription',
    destroyOnUnmount: true,
})(CreateSubscription);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('createSubscription')(state)
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);