import request from '../shared/request';

function createUser(data) {
    return request({
        url: '/api/users',
        method: 'POST',
        data: data
    })
}

function getUsers() {
    return request({
        url: '/api/users',
        method: 'GET'
    })
}

function getUserById(id) {
    return request({
        url: `/api/users/${id}`,
        method: 'GET'
    })
}

function editUser(data, id) {
    return request({
        url: `/api/users/${id}`,
        method: 'PUT',
        data: data
    })
}

function editMFASecret(data, id) {
    return request({
        url: `/api/users/${id}/mfa`,
        method: 'PUT',
        data: data
    })
}

function deleteUser(id) {
    return request({
        url: `/api/users/${id}`,
        method: 'DELETE'
    })
}

const UserService = {
    createUser,
    getUsers,
    getUserById,
    editUser,
    editMFASecret,
    deleteUser
};

export default UserService;