import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {connect} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import userManager from './../../utils/userManager';
import {populateClaims, populateCurrentUserClaims} from "./actions";
import GlobalSearch from "../../components/GlobalSearch";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileMenuActive: false,
            roles: []
        }
    }

    triggerMobileNavigation() {
        if(isMobileOnly) {
            if (!this.state.mobileMenuActive) {
                document.body.parentElement.classList.toggle('prevent-scroll', !this.state.mobileMenuActive);
            } else {
                document.body.parentElement.classList.remove('prevent-scroll');
            }
            this.setState({
                mobileMenuActive: !this.state.mobileMenuActive
            });
        }
    }

    logout() {
        userManager.signoutRedirect();
    }

    render() {

        let {userProfile} = this.props;

        return(
            <nav className={'nav border-b-2 shadow-lg'}>
                <div className="wrapper">
                    <div className="nav__row">
                        <a href="/dashboard/accounts" className="assure-id-logo-black-red"></a>
                        <div className="nav__container">
                            <a href="#" className={`nav__trigger ${this.state.mobileMenuActive ? 'is-active' : ''}`} onClick={(e) => {e.preventDefault(); this.triggerMobileNavigation()}}></a>
                            <div className="nav__menu flex items-center">
                                <div className={'flex flex-1 px-8'}>
                                    <div className="nav__links-container">
                                        <ul className="nav__links">
                                            <li>
                                                <NavLink onClick={(e) => {e.preventDefault(); this.triggerMobileNavigation(); this.props.history.push('/dashboard/accounts')}} className="nav__link" to="/dashboard/accounts" activeClassName="is-current">Accounts</NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={(e) => {e.preventDefault(); this.triggerMobileNavigation(); this.props.history.push('/dashboard/users')}} className="nav__link" to="/dashboard/users" activeClassName="is-current">Users</NavLink>
                                            </li>
                                            <PermissibleRender
                                                userPermissions={this.props.userClaims}
                                                requiredPermissions={['RoleViewRole']}
                                                oneperm
                                            >
                                            <li>
                                                <NavLink onClick={(e) => {e.preventDefault(); this.triggerMobileNavigation(); this.props.history.push('/dashboard/roles')}} className="nav__link" to="/dashboard/roles" activeClassName="is-current">Roles</NavLink>
                                            </li>
                                            </PermissibleRender>
                                        </ul>
                                    </div>
                                    <GlobalSearch />
                                </div>
                                <div className="nav__profile-container">
                                    <ul className="nav__profile">
                                        <li><span className="profile-name">{userProfile.fullName}</span></li>
                                        <li><a href="#" onClick={(e) => {e.preventDefault(); this.logout()}} className="f-link bold no-pad">Log Out</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        userProfile: state.userProfile,
        claims: state.config.claims,
        userClaims: state.userClaims
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({populateClaims, populateCurrentUserClaims}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));