import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {debounce} from 'lodash';
import AccountService from "../services/api/Account";
import {useGetAccounts} from "../services/api/hooks/account/useGetAccounts";
import {take} from 'lodash';
import {
    useGetSubscriptions
} from "../services/api/hooks/subscriptions/useGetSubscriptions";
import {useGetUsers} from "../services/api/hooks/users/useGetUsers";
import GlobalSearchColumn from './GlobalSearchColumn'
import Select from "react-select";
import {customStyles} from "../inputs/select";

const initialState = [{title: 'Accounts'}, {title: 'Subscriptions'}, {title: 'Users'}];
const dropdownOptions = [{value: 'All results', label: 'All results'}, {value: 'Accounts', label: 'Accounts'}, {value: 'Subscriptions', label: 'Subscriptions'}, {value: 'Users', label: 'Users'}]

const GlobalSearch = (props) => {

    const [search, setSearch] = React.useState('');
    const [searchResultsVisible, setSearchResultsVisible] = useState(false);

    const {data: accounts, isLoading: isLoadingAccounts} = useGetAccounts();
    const {data: users} = useGetUsers();
    const {data: subscriptions} = useGetSubscriptions();

    const accountsResults = useMemo(() => (accounts ?? []).filter(account => account.accountId).filter(account => account.name.toLowerCase().includes(search.toLowerCase())), [search, isLoadingAccounts]);
    const usersResults = useMemo(() => (users ?? []).filter(user => user.fullName.toLowerCase().includes(search.toLowerCase()) || user.name.toLowerCase().includes(search.toLowerCase())), [search]);
    const subscriptionsResults = useMemo(() => (subscriptions ?? []).filter(subscription => subscription.id.toLowerCase().includes(search.toLowerCase()) || subscription.name.toLowerCase().includes(search.toLowerCase())), [search]);

    const getDataByColumn = useCallback((column) => {
        switch (column.title) {
            case 'Accounts':
                return accountsResults;
            case 'Users':
                return usersResults
            case 'Subscriptions':
                return subscriptionsResults
            default:
                return [];
        }
    }, [accountsResults, usersResults]);

    const [columns, setColumns] = useState(initialState)

    const debouncedSearch = debounce(setSearch, 100);

    useEffect(() => {
        if (search.length > 0) {
            setSearchResultsVisible(true);
        } else {
            setSearchResultsVisible(false);
        }
    }, [search])

    useEffect(() => {

        const onEscapeKeyPressed = (event) => {
            if (event.key === 'Escape') {
                setSearchResultsVisible(false)
            }
        }

        document.addEventListener('keydown', onEscapeKeyPressed)
        return () => document.removeEventListener('keydown', onEscapeKeyPressed)
    }, [])

    useEffect(() => {
        if (searchResultsVisible) {
            setColumns(initialState)
            document.querySelector('html').classList.add('prevent-scroll');
        } else {
            document.querySelector('html').classList.remove('prevent-scroll');
        }
    }, [searchResultsVisible])

    return (
        <div className={'z-50 font-Raleway'}>
            <div className="search__container ">
                <div className="f-element search">
                    <input onClick={e => e.preventDefault()} className="search-submit search-icon" type="submit" value="" />
                    <input onFocus={(e) => {
                        if (e.target.value.length > 0 && !searchResultsVisible) {
                            setSearchResultsVisible(true);
                        }
                    }} className={'font-bold'} style={{fontWeight: 'bold'}} placeholder={'Search for anything'} disabled={false} type="text" name="search" onChange={(e) => {
                        e.preventDefault();
                        let value = e.target?.value ?? '';
                        if (value.length > 0) {
                            debouncedSearch(value);
                        } else {
                            setSearch('');
                        }
                    }}/>
                </div>
            </div>

            <AnimatePresence initial={false} presenceAffectsLayout={true}>
                {searchResultsVisible && (
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 0}}  style={{background: 'rgba(0, 0, 0, 0.2)'}} className={'absolute h-full w-full top-[88px] left-0 right-0 z-50'}>
                        <motion.div initial={{opacity: 0, y: -100}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 0}}  className={'w-[90%] bg-white mt-2 drop-shadow-[0_0_12px_rgba(0,0,0,0.18)] h-[876px] m-auto flex flex-col py-6 font-Raleway'}>
                            <div className={'flex justify-between items-center border-b-2 pb-4 px-10'}>
                                <span className={'font-bold'} style={{fontSize: 24}}>Search Results ({accountsResults?.length + usersResults?.length + subscriptionsResults?.length})</span>
                                <div className={'flex w-64 items-center font-medium'} style={{fontSize: 16}}>
                                    <span style={{color: '#828282'}}>Show</span>
                                    <Select
                                        options={dropdownOptions}
                                        onChange={(v) => {
                                            if (v.value === 'All results') {
                                                setColumns(initialState);
                                            } else {
                                                setColumns(initialState.filter(column => column.title === v.value));
                                            }
                                        }}
                                        value={columns.length > 1 ? dropdownOptions[0] : dropdownOptions.find(option => option.value === columns[0].title)}
                                        styles={{...customStyles, control: provided => ({
                                                ...provided,
                                                fontFamily: 'Raleway',
                                                fontWeight: 500,
                                                backgroundColor: "#fafafa",
                                                height: "50px",
                                                minHeight: "50px",
                                            })}}
                                        className={'ml-4 w-full'}
                                        />
                                </div>
                            </div>
                            <div className={'flex flex-1 gap-3'}>
                                {columns.map(column => {
                                    const data = getDataByColumn(column);
                                    return <GlobalSearchColumn search={search} hideSearchResults={() => setSearchResultsVisible(false)} key={column.title} column={column} data={data} expanded={columns.length === 1} triggerExpand={() => {
                                        if (columns.length === 1) {
                                            setColumns(initialState)
                                        } else {
                                            setColumns([column])
                                        }
                                    }}/>
                                })}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default GlobalSearch;