import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form'
import AccountService from "../../../services/api/Account";
import {required, validateUniqueCustomerID} from "../../../inputs/validations";
import {connect} from "react-redux";
import {SelectInput} from "../../../inputs/select";
import {InputField} from "../../../inputs/standard";

class EditAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            accounts: null
        }
    }

    componentDidMount() {
        if (this.props.selectedAccount) {

            AccountService.getAccounts(10)
                .then(accounts => {
                    accounts = accounts.map(account => {
                        return {value: account.id, label: account.name, customerId: account.customerId}
                    }).sort((a, b) => a.label.localeCompare(b.label));
                    this.setState({
                        accounts
                    }, () => {
                        AccountService.getAccount(this.props.selectedAccount)
                            .then(account => {
                                this.setState({account});
                                let parentAccount = this.state.accounts.filter(a => a.value === account.accountId);
                                this.props.initialize({
                                    "customerId": account.customerId,
                                    "createdDate": account.createdDate,
                                    "modifiedDate": account.modifiedDate,
                                    "accountId": parentAccount[0],
                                    "id": account.id,
                                    "name": account.name
                                });
                            })
                            .catch(err => {
                                throw new Error(err);
                            });
                    });
                })
                .catch(err => {
                    throw new Error(err);
                });
        }
    }

    onSubmit() {
        let {formValues} = this.props;
        if (!formValues.accountId.value) {
            throw new SubmissionError({'accountId': 'This field is required.'})
        }
        formValues.accountId = formValues.accountId.value;
        return AccountService.editAccount(this.state.account.id, formValues)
            .then(account => {
                this.props.triggerEditAccountModal();
            })
            .catch(err => {
                throw new SubmissionError({...err.data});
            })

    }

    render() {

        if (!this.state.account) return null;

        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">Edit account</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerEditAccountModal()}}></span>
                </div>
                <div className="e-modal__body">
                    <div className="f-container account-name">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="account-name">Account Name</label>
                                        <Field validate={[required]}
                                               className={'field'}
                                               component={InputField}
                                               type="text"
                                               name="name"
                                               placeholder="Enter account name"
                                               alt="Enter account name"
                                               tabIndex="1"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="customer-id">Customer Id</label>
                                        <Field validate={[required, validateUniqueCustomerID({accounts: this.state.accounts, initialValue: this.state.account.customerId})]}
                                               className={'field'}
                                               component={InputField}
                                               type="text"
                                               name="customerId"
                                               placeholder="Enter customer id ex:123456789"
                                               alt="Enter customer id ex:123456789"
                                               tabIndex="2"
                                        />
                                    </div>
                                </div>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="accountId">Parent account</label>
                                        <Field options={this.state.accounts} validate={[required]} name="accountId" component={SelectInput} tabIndex="4" />
                                    </div>
                                </div>
                                <div className="f-row cta-container">
                                    <div className="f-element alpha">
                                        <button className={'cta'}>Save Changes</button>
                                    </div>
                                    <div className="f-element beta">
                                        <button className="light" onClick={(e) => {e.preventDefault(); this.props.triggerEditAccountModal()}}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

EditAccount = reduxForm({
    form: 'editAccount',
    destroyOnUnmount: true
})(EditAccount);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('editAccount')(state),
        keepDirtyOnReinitialize: true,
    }
}

export default connect(mapStateToProps)(EditAccount);