import request from '../shared/request';

function getClaims() {
    return request({
        url: '/api/claims'
    });
}

const ClaimsService = {
    getClaims
};

export default ClaimsService;