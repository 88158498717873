import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
    client_id: 'ConnectAdminWebClient',
    redirect_uri: `${window.env.REDIRECT_URL}/callback`,
    silent_redirect_uri: `${window.env.REDIRECT_URL}/silentrenew`,
    post_logout_redirect_uri: window.env.REDIRECT_URL,
    response_type: 'id_token token',
    scope: 'openid profile AdminAPI claims',
    authority: window.env.AUTHORITY_URL,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
    includeIdTokenInSilentRenew: false
};

const userManager = createUserManager(userManagerConfig);

export default userManager;