import request from '../shared/request';

function getProfile() {
    return request({
        url: '/api/profile',
        method: 'GET'
    })
}

const ProfileService = {
    getProfile
};

export default ProfileService;