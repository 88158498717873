import axios from 'axios'
import Cookies from 'universal-cookie';
import {store} from './../../store';
import userManager from "../../utils/userManager";
/*
 global window
 */

const cookies = new Cookies();
const client = (() => {
    return axios.create({
        baseURL: window.env.API_URL
    });
})();

const request = function(options, headers) {
    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    };

    options.headers = {
        "Authorization": "Bearer " + cookies.get('token'),
        ...headers
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

client.interceptors.request.use(config => {
    store.dispatch({type: "@@acuant/TICK"})
    return config;
});

client.interceptors.response.use(config => {
    return config;
}, err => {
    if (err.response && err.response.status === 401) {
        userManager.signoutRedirect();
    }
    return Promise.reject(err);
});

export default request;