import React, {Fragment} from 'react';
import Select from 'react-select';
export const customStyles = {
    indicatorsContainer: provided => ({
        ...provided,
        height: "50px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    menu: provided => ({
        ...provided,
        fontFamily: 'Raleway',
        fontWeight: 500
    }),
    input: provided => ({
        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0
    })
};
export const SelectInput = (field) => (
    <Fragment>
        <Select
            styles={{...customStyles, control: provided => ({
                    ...provided,
                    fontFamily: 'Raleway',
                    fontWeight: 500,
                    backgroundColor: "#fafafa",
                    borderWidth: !field.disabled && field.meta.touched && field.meta.error ? 2 : 1,
                    borderColor: !field.disabled && field.meta.touched && field.meta.error ? '#dc3545' : 'hsl(0,0%,80%);',
                    height: "50px",
                    minHeight: "50px",
                })}}
            id="state-select"
            className={`is-fullwidth is-auto ${field.meta.touched && field.meta.error ? 'is-invalid' : ''}`}
            clearable={field.clearable}
            value={field.input.value}
            onChange={field.input.onChange}
            options={field.options}
            name="selected-state"
            isSearchable={true}
            style={field.style}
            isMulti={field.multi}
            isDisabled={field.disabled}
            valueComponent={field.valueComponent}
            backspaceRemoves={field.backspaceRemoves}
            searchable={field.searchable || false}
        />
        {!field.disabled && field.meta.touched && (field.meta.error && <p className="help is-danger">{field.meta.error}</p>)}
    </Fragment>
)
