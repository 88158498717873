import React, {Component} from 'react';
import {Field, getFormValues, reduxForm, SubmissionError} from "redux-form";
import {connect} from "react-redux";
import {InputField} from "../../../../inputs/standard";
import {passwordComplexity, passwordsMatch, required} from "../../../../inputs/validations";
import UserService from "../../../../services/api/Users";
import RolesService from "../../../../services/api/Roles";
import {SelectInput} from "../../../../inputs/select";


class UserDetails extends Component {


    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            roleValues: []
        }
    }

    componentWillMount() {
        RolesService.getRoles()
            .then(roles => {
                console.log(roles);
                let roleValues = roles.map(role => {return {value: role.id, label: role.name}})
                this.setState({roles, roleValues});
            })
            .catch(err => {
                throw new Error(err);
            })

    }

    onSubmit() {
        let {formValues} = this.props;

        /**
         * If redux-form check passes, just delete the role from the values
         * since it's not required
         */

        delete formValues.role;
        formValues = {...formValues, accountId: this.props.newAccountData.id, isEnabled: true};
        return UserService.createUser(formValues)
            .then(user => {
                formValues = {...formValues, id: user.id};
                UserService.editUser(formValues, user.id)
                    .then(() => {
                        this.props.triggerModal();
                    })
                    .catch(err => {
                        throw new SubmissionError({...err.data})
                    })
            })
            .catch(err => {
                throw new SubmissionError({...err.data})
            })
    }

    render() {
        if (!this.props.newAccountData) return null;

        return (
            <div className="f-container asign-admin">
                <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                    <div className="f-section">
                        <h3 className="f-section__title">Step 2 of 2 - Assign Admin to {this.props.newAccountData.name}</h3>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="admin-name">Admin Name</label>
                                <Field
                                    className={'field'}
                                    component={InputField}
                                    type="text"
                                    validate={[required]}
                                    name="fullName"
                                    placeholder="Enter admin name"
                                    alt="Enter admin name"
                                    tabIndex="1"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="admin-email">Admin Email</label>
                                <Field
                                    className={'field'}
                                    component={InputField}
                                    type="text"
                                    validate={[required]}
                                    name="name"
                                    placeholder="Enter admin username"
                                    alt="Enter admin username"
                                    tabIndex="2"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="admin-password">Admin Password</label>
                                <Field
                                    className={'field'}
                                    component={InputField}
                                    type="password"
                                    validate={[required, passwordsMatch(), passwordComplexity()]}
                                    name="password"
                                    placeholder="Enter admin password"
                                    alt="Enter admin password"
                                    tabIndex="3"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="user-description">Confirm admin password</label>
                                <Field
                                    component={InputField}
                                    validate={[required, passwordsMatch()]}
                                    className={'field'}
                                    type="password"
                                    name="confirm_password"
                                    placeholder="Confirm user password"
                                    alt="Confirm user password"
                                    tabIndex="3"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="accountId">Select a role</label>
                                <Field
                                    validate={[required]}
                                    name="role"
                                    component={SelectInput}
                                    tabIndex="4"
                                    options={this.state.roleValues}
                                    onChange={(event, newValue) => {
                                        let role = this.state.roles.filter(role => role.id === newValue.value);
                                        this.props.change('roles', role);
                                        this.props.change('role', newValue);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="f-row" style={{margin: 0}}>
                            <div className="f-element">
                                <Field
                                    component={InputField}
                                    type={'hidden'}
                                    className="field"
                                    name="error"
                                    placeholder="Enter name"
                                    alt="Enter name"
                                    tabIndex="1" />
                            </div>
                        </div>
                        <div className="f-row cta-container">
                            <div className="f-element alpha">
                                <button className={'cta'}>Assign Admin</button>
                            </div>
                            <div className="f-element beta">
                                <button className="light" onClick={(e) => {e.preventDefault(); this.props.triggerModal()}}>Cancel</button>
                            </div>
                            <div className="f-element gamma">
                                <button className="light" onClick={(e) => {e.preventDefault(); this.props.triggerModal()}}>Skip This Step</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}


UserDetails = reduxForm({
    form: 'userDetails',
    destroyOnUnmount: true
})(UserDetails);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('userDetails')(state),
        keepDirtyOnReinitialize: true
    }
}

export default connect(mapStateToProps)(UserDetails);