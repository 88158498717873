import { reducer as formReducer } from 'redux-form';
import { reducer as oidcReducer } from 'redux-oidc';
import {configReducer, requestReducer} from './screens/shared/reducers'
import {userReducer} from "./screens/Auth/reducers";
import {userClaims} from "./screens/shared/reducers";
import { connectRouter } from 'connected-react-router'

export default (history) => {
    return {
        router: connectRouter(history),
        form: formReducer,
        oidc: oidcReducer,
        config: configReducer,
        userProfile: userReducer,
        userClaims: userClaims,
        request: requestReducer
    }
}