import {createStore, applyMiddleware} from 'redux';
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { composeWithDevTools } from 'redux-devtools-extension';
import {persistStore, persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const loggerMiddleware = store => next => action => {
    console.log("[LOG] action triggered", action);
    next(action);
};

export const history = createHistory();


/*
    TODO: Add epic middleware for action bypass
 */

const initialState = {
        request: {
            updatedAt: null
        }
    },
    enhancers = [],
    middleware = [
        thunk,
        routerMiddleware(history),
        loggerMiddleware
    ];

const composedEnhancers = composeWithDevTools(
    applyMiddleware(...middleware),
    ...enhancers
);

const config = {
    key: 'assureidconnect',
    storage,
    blacklist: ['form', 'router'],
};

const reducer = persistCombineReducers(config, rootReducer(history));

function configureStore () {
    let store = createStore (
        reducer,
        initialState,
        composedEnhancers
    );

    let persistor = persistStore(store);

    return {persistor, store};
}

export const {persistor, store} = configureStore();