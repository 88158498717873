import React, {Component, Fragment} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form';
import SwitchButton from 'lyef-switch-button';
import SubscriptionService from "../../../services/api/Subscriptions";
import {connect} from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {bindActionCreators} from "redux";
import {InputField} from "../../../inputs/standard";
import {required} from "../../../inputs/validations";
import {DayPicker} from "../../../inputs/dayPicker";
import moment from "moment/moment";
import {SelectInput} from "../../../inputs/select";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import userHasPermission from "../../../utils/checkPermissions";
import {RadioButtons} from "../../../inputs/radioButtons";
import checkPermissions from "../../../utils/checkPermissions";
import {ReactComponent as AttentionRequired} from "./../../../images/icons/i_attention_required.svg";
import {queryClient} from "../../../App";

class EditSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscription: null,
            copied: false,
            userModalOpen: false,
            productTypes: [
                {label: 'Data Capture', value: 1},
                {label: 'Authentication', value: 2},
                {label: 'Barcode', value: 3}
            ],
            ttlOptions: [
                {
                    label: "Hourly",
                    value: "hourly",
                },
                {
                    label: "Daily",
                    value: "daily",
                },
                {
                    label: "Weekly",
                    value: "weekly",
                },

            ],
            products: {
                assureIdEnhanced: false,
                assureIdLite: false,
                loqate: false,
                enhancedTampering: false,
                shareDiagnostics: false,
                review: 'false',
                ozone: false,
                chipExtraction: false
            },
            subscriptionEnabled: false,
            subscriptionTypes: [{label: 'Production', value: 'Production'}, {label: 'Development', value: 'Development'}]
        }
    }

    getAuthorizedProducts(subscriptionId) {
        if (userHasPermission(this.props.userClaims, ['IsAcuant', 'IsCloud'])) {
            SubscriptionService.getAuthorization(subscriptionId)
                .then(response => {
                    let products = JSON.parse(JSON.stringify(this.state.products));
                    if (response) {
                        this.props.change('facialMatch', response.facialMatch)
                        this.props.change('assureid', response.assureid);
                        this.props.change('facialLiveness', response.passiveLiveness ? 'passiveLiveness' : '');
                        this.setState({
                            products: {
                                review: response.review,
                                ozone: response.ozone,
                                assureIdEnhanced: response['assureid/features/enhancedOcr'],
                                chipExtraction: response['mobile/features/chipExtract'],
                                assureIdLite: response['assureid/features/lite'],
                                loqate: response['assureid/features/loqate'],
                                enhancedTampering: response['assureid/features/enhancedTampering'],
                                shareDiagnostics: response['assureid/features/shareDiagnostics'],
                            }
                        })
                    }
                })
                .catch(err => {

                })
        }
    }

    componentDidMount() {
        SubscriptionService.getSubscriptionById(this.props.subscriptionId)
            .then(subscription => {
                this.setState({subscription});
                subscription.jwksTtl = this.state.ttlOptions.filter(v => v.value === subscription.jwksTtl)[0];
                subscription.licenseTypeName = this.state.subscriptionTypes.filter(subscriptionType => subscriptionType.value === subscription.licenseTypeName);
                if (moment(subscription.expirationDate).isBefore(moment().endOf('day'))) {
                    this.setState({
                        subscriptionEnabled: false
                    })
                } else {
                    this.setState({
                        subscriptionEnabled: true
                    })
                }
                this.props.initialize(subscription);
                this.getAuthorizedProducts(this.props.subscriptionId);
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    onSubmit(values) {
        let data = JSON.parse(JSON.stringify(values));
        let self = this;
        data.licenseTypeName = data.licenseTypeName.value;
        data.documentProcessMode = data.documentProcessMode ?? 0;
        data.jwksUrl = data?.jwksUrl ?? null
        data.jwksTtl = data.jwksTtl?.value ?? null
        const expirationDate = moment(this.state.subscription?.expirationDate)
        if (this.state.products.liveness) {
            this.state.products.facialMatch = true;
        }
        if (!this.state.subscriptionEnabled) {
            if (expirationDate.isAfter(moment().endOf('day'))) {
                data.expirationDate = moment.utc().subtract(2, 'day').format();
            }
        } else {
            if (expirationDate.isBefore(moment().endOf('day'))) {
                data.expirationDate = "9999-12-31T23:59:59.999999";
            }
        }
        if (userHasPermission(this.props.userClaims, ['IsAcuant', 'IsCloud'])) {
            let authorizationObject = {
                'passiveLiveness': data['facialLiveness'] === 'passiveLiveness',
                'facialMatch': data['facialMatch'] || '',
                'ozone': this.state.products.ozone,
                'mobile/features/chipExtract': this.state.products.chipExtraction,
                'review': this.state?.products?.review ?? false,
                'assureid': !!data.documentProcessMode ?? false,
                'assureid/features/enhancedOcr': this.state.products.assureIdEnhanced,
                'assureid/features/lite': this.state.products.assureIdLite,
                'assureid/features/loqate': this.state.products.loqate,
                'assureid/features/enhancedTampering': this.state.products.enhancedTampering,
                'assureid/features/shareDiagnostics': this.state.products.shareDiagnostics
            }
            SubscriptionService.updateAuthorization(this.props.subscriptionId, JSON.stringify(JSON.stringify(authorizationObject)))
                .then(() => {
                    SubscriptionService.editSubscription(this.props.subscriptionId, data)
                        .then(result => {
                            self.props.triggerEditSubModal();
                        })
                        .catch(err => {
                            throw new SubmissionError({...err.data});
                        })
                })
                .catch(err => {
                    throw new SubmissionError({...err.data})
                })
        } else {
            SubscriptionService.editSubscription(this.props.subscriptionId, data)
                .then(result => {
                    queryClient.refetchQueries(['subscriptions']);
                    self.props.triggerEditSubModal();
                })
                .catch(err => {
                    throw new SubmissionError({...err.data});
                })
        }

        /*return */

    }

    render() {
        if (!this.state.subscription || !this.props.id) return null;
        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">Edit Subscription</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerEditSubModal()}}></span>
                </div>
                <div className="e-modal__body">
                    <div className="f-container edit-subscription">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="subscription-name">Subscription Name</label>
                                        <Field
                                            component={InputField}
                                            type="text"
                                            name="name"
                                            className='field'
                                            placeholder="Enter subscription name"
                                            alt="Enter subscription name"
                                            validate={[required]}
                                            tabIndex="1"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">

                                    <div className="f-element action">
                                        <label htmlFor="subscription-id">Subscription Id</label>
                                        <Field
                                            component={InputField}
                                            type="text"
                                            disabled
                                            name="id"
                                            tabIndex="2"
                                            className="field"
                                            validate={[required]}
                                        />
                                        <CopyToClipboard text={this.props.id} onCopy={() => this.setState({copied: true})}>
                                            <a className="action-copy">{this.state.copied ? 'Copied' : 'Copy'}</a>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="expiration-date">Expiration Date</label>
                                        <Field
                                            component={DayPicker}
                                            placeholder={"MM-DD-YYYY"}
                                            name="expirationDate"
                                            disabled={true}
                                            validate={[required]}
                                            alt="Choose date"
                                            tabIndex="3"
                                            dayPickerProps={{
                                                disabledDays: {before: moment()._d}
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="subscription-type">Subscription Type</label>
                                        <Field
                                            name="licenseTypeName"
                                            component={SelectInput}
                                            tabIndex="4"
                                            options={this.state.subscriptionTypes}
                                            validate={[required]}
                                            onChange={(event, newValue) => {
                                                //event.preventDefault();
                                                if (newValue.value === 'Production') {
                                                    this.props.change('licenseType', 1);
                                                } else {
                                                    this.props.change('licenseType', 2);
                                                }
                                                this.props.change('licenseTypeName', newValue);
                                            }}
                                        />
                                        <Field component={InputField} type='hidden' name={'licenseType'}/>
                                    </div>
                                </div>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['IsCloud']}
                                >
                                    <div className="f-row border-top">
                                        <div className="context">
                                            <div className="context__cell alpha">
                                                <span className="context__title">Enable public key client authentication</span>
                                            </div>
                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`authorizationScheme`}
                                                    isChecked={this.props?.formValues?.authorizationScheme === "Bearer" ?? false}
                                                    action={() => {
                                                        if (checkPermissions(this.props.userClaims, ['IsCloud', 'IsAcuant'])) {
                                                            if (this.props.formValues['authorizationScheme'] === 'Basic') {
                                                                this.props.change('authorizationScheme', "Bearer");
                                                                console.log(this.props.formValues.jwksTtl)
                                                                if (!this.props.formValues.jwksTtl?.value) {
                                                                    setTimeout(() => {
                                                                        this.props.change('jwksTtl', {
                                                                            label: 'Hourly',
                                                                            value: 'hourly'
                                                                        })
                                                                    }, 100)
                                                                }
                                                            } else {
                                                                this.props.change('authorizationScheme', "Basic");
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {(this.props?.formValues?.authorizationScheme ?? "Basic") !== 'Basic' &&
                                        <Fragment>
                                            <div className={'f-row'} style={{width: '100%'}}>
                                                <div className="f-element">
                                                    <label htmlFor="subscription-type">Configure a jwks url to enable public key authorization mechanisms</label>
                                                    <Field component={InputField}
                                                           type="text"
                                                           validate={[required]}
                                                           className='field'
                                                           placeholder="Enter JWKS URL"
                                                           alt="Enter JWKS URL"
                                                           tabIndex="1"
                                                           fieldName={'jwksUrl'}
                                                           name={'jwksUrl'}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'f-row'} style={{width: '100%'}}>
                                                <div className="f-element">
                                                    <label htmlFor="subscription-type">Time to live (Poll time to check for new certificates)</label>
                                                    <Field component={SelectInput}
                                                           fieldName={'jwksTtl'}
                                                           validate={[required]}
                                                           style={{width: '100%'}}
                                                           name={'jwksTtl'}
                                                           options={
                                                               [
                                                                   {
                                                                       label: "Hourly",
                                                                       value: "hourly",
                                                                   },
                                                                   {
                                                                       label: "Daily",
                                                                       value: "daily",
                                                                   },
                                                                   {
                                                                       label: "Weekly",
                                                                       value: "weekly",
                                                                   },

                                                               ]
                                                           }/>
                                                </div>
                                            </div>
                                        </Fragment>
                                        }
                                    </div>
                                </PermissibleRender>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Assure ID</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`assureid`}
                                                isChecked={!!this.props.formValues['documentProcessMode']}
                                                action={() => {
                                                    if (checkPermissions(this.props.userClaims, ['IsAcuant'])) {
                                                        if (!this.props.formValues['documentProcessMode']) {
                                                            this.props.change('documentProcessMode', 1);
                                                        } else {
                                                            this.props.change('documentProcessMode', 0);
                                                            this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdEnhanced: false
                                                                }
                                                            })
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PermissibleRender
                                        userPermissions={this.props.userClaims}
                                        requiredPermissions={['IsCloud']}
                                        oneperm
                                    >
                                        <Fragment>
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">AssureID Enhanced</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`assureid_enhanced`}
                                                            isChecked={this.state.products.assureIdEnhanced}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdEnhanced: !this.state.products.assureIdEnhanced
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">AssureID Lite</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`assureid_lite`}
                                                            isChecked={this.state.products.assureIdLite}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    assureIdLite: !this.state.products.assureIdLite
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Loqate Address Services</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`loqate`}
                                                            isChecked={this.state.products.loqate}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    loqate: !this.state.products.loqate
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                                <div className="context child">
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Enhanced Tampering</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <SwitchButton
                                                            id={`enhancedTampering`}
                                                            isChecked={this.state.products.enhancedTampering}
                                                            action={() => this.setState({
                                                                products: {
                                                                    ...this.state.products,
                                                                    enhancedTampering: !this.state.products.enhancedTampering
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {this.props.formValues['documentProcessMode'] > 0 &&
                                            <div className="context child">
                                                <div className="context__cell alpha">
                                                    <span className="context__title">Share diagnostic data (including PII)</span>
                                                </div>
                                                <div className="context__cell beta">
                                                    <SwitchButton
                                                        id={`shareDiagnostics`}
                                                        isChecked={this.state.products.shareDiagnostics}
                                                        action={() => this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                shareDiagnostics: !this.state.products.shareDiagnostics
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            }
                                        </Fragment>
                                    </PermissibleRender>
                                    {this.props.formValues['documentProcessMode']>0 &&
                                    <Field component={RadioButtons}
                                           style={{width: '100%'}}
                                           parentClassName={'custom-radio__container'}
                                           fieldName={'documentProcessMode'}
                                           name={'documentProcessMode'}
                                           options={
                                               [
                                                   {
                                                       id: "datacapture",
                                                       label: "Data Capture",
                                                       value: 1,
                                                   },
                                                   {
                                                       id: "authentication",
                                                       label: "Authentication",
                                                       value: 2,
                                                   },
                                                   {
                                                       id: "barcode",
                                                       label: "Barcode",
                                                       value: 3,
                                                   },

                                               ]
                                           }/>
                                    }
                                </div>
                                <div className="f-row border-top" style={{display: 'none'}}>
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title large">Users asigned to this subscription</span>
                                        </div>
                                        <div className="context__cell beta inline-menu__container">
                                            <a onClick={() => {this.setState({userModalOpen: !this.state.userModalOpen})}}
                                               className={`context__trigger inline-menu__trigger ${this.state.userModalOpen ? 'is-active' : ''}`}>
                                                + Add User
                                            </a>
                                            <div className="inline-menu tablet-and-above arrow top right">
                                                <div className="aum">
                                                    <h3 className="aum__title">Assign new user</h3>
                                                    <ul className="aum__list">
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">John Doe</span>
                                                                <span className="aum__email">admin@assureid.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-one" id="select-one" />
                                                                <label htmlFor="select-one"></label>
                                                            </div>
                                                        </li>
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">Tim Lucia</span>
                                                                <span className="aum__email">admin@tlucia.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-two" id="select-two" />
                                                                <label htmlFor="select-two"></label>
                                                            </div>
                                                        </li>
                                                        <li className="aum__row">
                                                            <div className="aum__cell alpha">
                                                                <span className="aum__name">Anthony Barngrazi</span>
                                                                <span className="aum__email">abarngrazi@acuantcorp.com</span>
                                                            </div>
                                                            <div className="aum__cell beta">
                                                                <input className="checkbox" type="checkbox" name="select-three" id="select-three" />
                                                                <label htmlFor="select-three"></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <button>Assign selected users (3)</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="aum mobile">
                                        <h3 className="aum__title">Assign new user</h3>
                                        <ul className="aum__list">
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">John Doe</span>
                                                    <span className="aum__email">admin@assureid.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-one" id="select-one" />
                                                    <label htmlFor="select-one"></label>
                                                </div>
                                            </li>
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">Tim Lucia</span>
                                                    <span className="aum__email">admin@tlucia.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-two" id="select-two" />
                                                    <label htmlFor="select-two"></label>
                                                </div>
                                            </li>
                                            <li className="aum__row">
                                                <div className="aum__cell alpha">
                                                    <span className="aum__name">Anthony Barngrazi</span>
                                                    <span className="aum__email">abarngrazi@acuantcorp.com</span>
                                                </div>
                                                <div className="aum__cell beta">
                                                    <input className="checkbox" type="checkbox" name="select-three" id="select-three" />
                                                    <label htmlFor="select-three"></label>
                                                </div>
                                            </li>
                                        </ul>
                                        <button>Assign selected users (3)</button>
                                    </div>

                                    <div className="e-table">

                                        <div className="e-table__row">

                                            <div className="e-table__cell alpha">
                                                <span className="e-table__name remove-margins">John Doe</span>
                                                <span className="e-table__data">admin@assureid.com</span>
                                            </div>
                                            <div className="e-table__cell beta display-tablet-and-above">
                                                <span className="e-table__data bold uppercase tiny vertically-centered">Administrator</span>
                                            </div>
                                            <div className="e-table__cell control_cell min-height">
                                                <a className="control">Remove</a>
                                            </div>

                                        </div>
                                        <div className="e-table__row">

                                            <div className="e-table__cell alpha">
                                                <span className="e-table__name remove-margins">Tim Lucia</span>
                                                <span className="e-table__data">tlucia@acuantcorp.com</span>
                                            </div>
                                            <div className="e-table__cell beta display-tablet-and-above">
                                                <span className="e-table__data bold uppercase tiny vertically-centered">Manager</span>
                                            </div>
                                            <div className="e-table__cell control_cell min-height">
                                                <a className="control">Remove</a>
                                            </div>

                                        </div>
                                        <div className="e-table__row">

                                            <div className="e-table__cell alpha">
                                                <span className="e-table__name remove-margins">Anthony Banrgrazi</span>
                                                <span className="e-table__data">abangrazi@acuantcorp.com</span>
                                            </div>
                                            <div className="e-table__cell beta display-tablet-and-above">
                                                <span className="e-table__data bold uppercase tiny vertically-centered">Troublemaker</span>
                                            </div>
                                            <div className="e-table__cell control_cell min-height">
                                                <a className="control">Remove</a>
                                            </div>

                                        </div>
                                        <div className="e-table__row">

                                            <div className="e-table__cell alpha">
                                                <span className="e-table__name remove-margins">Eric Oulette</span>
                                                <span className="e-table__data">ericoulette@acuantcorp.com</span>
                                            </div>
                                            <div className="e-table__cell beta display-tablet-and-above">
                                                <span className="e-table__data bold uppercase tiny vertically-centered">User</span>
                                            </div>
                                            <div className="e-table__cell control_cell min-height">
                                                <a className="control">Remove</a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="f-row border-top">
                                    <div className="context">

                                        <div className="context__cell alpha">
                                            <span className="context__title">Store Personally Identifiable Information (PII)</span>
                                        </div>

                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`store-pii`}
                                                isChecked={this.props.storePii}
                                                action={() => {this.props.change('storePii', !this.props.storePii)}}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['IsCloud', 'IsAcuant']}
                                >
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Facial Match</span>
                                            </div>
                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`facialMatch`}
                                                    isChecked={this.props.formValues['facialMatch']}
                                                    action={() => {
                                                        if (!this.props.formValues['facialMatch']) {
                                                            this.props.change('facialMatch', 'social');
                                                        } else {
                                                            this.props.change('facialMatch', '');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.props.formValues['facialMatch'] &&
                                        <Field component={RadioButtons}
                                               style={{width: '100%'}}
                                               parentClassName={'custom-radio__container'}
                                               fieldName={'facialMatch'}
                                               options={
                                                   [
                                                       {
                                                           id: "social",
                                                           label: "Face Standard",
                                                           value: "social",
                                                       },
                                                       {
                                                           id: "nist",
                                                           label: "Face Government",
                                                           value: "nist",
                                                       },
                                                       {
                                                           id: "enhanced",
                                                           label: "Face Enhanced",
                                                           value: "enhanced",
                                                       },
                                                   ]
                                               }/>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Facial Liveness</span>
                                            </div>
                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`facialLiveness`}
                                                    isChecked={this.props.formValues['facialLiveness']}
                                                    action={() => {
                                                        if (!this.props.formValues['facialLiveness']) {
                                                            this.props.change('facialLiveness', 'passiveLiveness');
                                                        } else {
                                                            this.props.change('facialLiveness', '');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.props.formValues['facialLiveness'] &&
                                        <Field component={RadioButtons}
                                               style={{width: '100%'}}
                                               parentClassName={'custom-radio__container'}
                                               fieldName={'facialLiveness'}
                                               options={
                                                   [
                                                       {
                                                           id: "passiveLiveness",
                                                           label: "Passive Liveness",
                                                           value: "passiveLiveness",
                                                       }
                                                   ]
                                               }/>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Review</span>
                                            </div>

                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`review`}
                                                    isChecked={(this.state.products?.review?.toString() === 'true' || this.state?.products?.review?.toString() === 'mock')}
                                                    action={() => this.setState({
                                                        products: {
                                                            ...this.state.products,
                                                            review: (this.state.products?.review?.toString() === 'true' || this.state?.products?.review?.toString() === 'mock') ? 'false' : 'true'
                                                        }
                                                    })}
                                                />
                                            </div>

                                        </div>
                                        {(this.state.products?.review?.toString() === 'true' || this.state.products?.review?.toString() === 'mock') &&
                                        <div className="context child">
                                            <div
                                                className="context__cell alpha">
                                                        <span
                                                            className="context__title">Mock Response Mode</span>
                                            </div>

                                            <div
                                                className="context__cell beta">
                                                <SwitchButton
                                                    id={`mock`}
                                                    isChecked={this.state.products?.review?.toString() === 'mock'}
                                                    action={() => this.setState({
                                                        products: {
                                                            ...this.state.products,
                                                            review: (this.state.products?.review?.toString() === 'mock') ? 'true' : 'mock'
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="f-row border-top">
                                        <div className="context">

                                            <div className="context__cell alpha">
                                                <span className="context__title">Ozone</span>
                                            </div>

                                            <div className="context__cell beta">
                                                <SwitchButton
                                                    id={`ozone`}
                                                    isChecked={this.state.products.ozone}
                                                    action={() => {
                                                        this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                ozone: !this.state.products.ozone
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        {this.state.products.ozone &&
                                            <div className="context child">
                                                <div className="context__cell alpha">
                                                    <span className="context__title">Mobile Extraction Only</span>
                                                </div>
                                                <div className="context__cell beta">
                                                    <SwitchButton
                                                        id={`chipExtraction`}
                                                        isChecked={this.state.products.chipExtraction}
                                                        action={() => this.setState({
                                                            products: {
                                                                ...this.state.products,
                                                                chipExtraction: !this.state.products.chipExtraction
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </PermissibleRender>
                                <div className="f-row border-top">
                                    <div className="context">

                                        <div className="context__cell alpha">
                                            <span className="context__title">Subscription Status</span>
                                        </div>

                                        <div className="context__cell beta">
                                            <div className="context__cell beta">
                                                <div className={'font-bold mr-4'} style={{color: '#828282'}}>{this.state.subscriptionEnabled ? 'Enabled' : 'Disabled'}</div>
                                                <SwitchButton
                                                    id={`subscriptionEnabled`}
                                                    isChecked={this.state.subscriptionEnabled}
                                                    action={() => this.setState({
                                                        ...this.state,
                                                        subscriptionEnabled: !this.state.subscriptionEnabled
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {!this.state.subscriptionEnabled && (moment(this.state.subscription.expirationDate).isAfter(moment().endOf('day'))) && (<div className={'flex flex-1 flex-row bg-gray-100 p-3 font-Raleway items-center'}>
                                        <AttentionRequired />
                                        <div className={'flex flex-col ml-4'}>
                                            <span>Disabling this subscription will move the expiration date to yesterday.</span>
                                            <span>Once you click <span className={'font-bold'}>"Save changes"</span> this subscription can no longer be used.</span>
                                        </div>
                                    </div>)}
                                </div>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row cta-container">

                                    <div className="f-element alpha">
                                        <button className={'cta'}>Save Changes</button>
                                    </div>

                                    <div className="f-element beta">
                                        <button onClick={e => {e.preventDefault(); this.props.triggerEditSubModal()}} className="light">Cancel</button>
                                    </div>

                                </div>

                            </div>

                        </form>

                    </div>

                </div>

            </div>

        );
    }
}

EditSubscription = reduxForm({
    form: 'editSubscription',
    destroyOnUnmount: true,
})(EditSubscription);


function mapStateToProps(state) {
    const selector = formValueSelector('editSubscription');
    return {
        id: selector(state, 'id'),
        storePii: selector(state, 'storePii'),
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        formValues: getFormValues('editSubscription')(state)
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscription);