import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Switch, Redirect, Route} from "react-router-dom";

import PrivateRoute from "../../utils/PrivateRoute";

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Accounts from "./Accounts";
import Roles from "./Roles";
import Users from "./Users";

class Dashboard extends Component{

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="page">
                <Route path={`${this.props.match.url}/accounts`} render={(props) => <Accounts {...this.props} />} />
                <Route path={`${this.props.match.url}/users`} render={(props) => <Users {...this.props} />} />
                <Route path={`${this.props.match.url}/roles`} render={(props) => <Roles {...this.props} />} />
                <Footer />
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        config: state.config,
        userProfile: state.userProfile,
        userClaims: state.userClaims
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

