import React, {Component} from 'react';
import UserManager from 'oidc-client';
import userManager from './../../utils/userManager';


export default class SilentRenew extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        userManager.signinSilentCallback();
    }

    render() {
        return null;
    }


}