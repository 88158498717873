import React, {Component} from 'react';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import UserService from "../../../services/api/Users";
import DeleteModal from "../../shared/DeleteModal";

export default class UserItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expandSubscriptions: false,
            userDetails: null,
            selectedUser: null,
            deleteError: false,
            deleteModalIsOpen: false,
            errorMessage: null
        }

        this.onDelete = this.onDelete.bind(this);
    }

    triggerSubscriptionRetrieval(id) {
        if (!this.state.expandSubscriptions) {
            UserService.getUserById(id)
                .then(user => {
                    this.setState({
                        userDetails: user,
                        expandSubscriptions: !this.state.expandSubscriptions
                    })
                })
                .catch(err => {
                    this.setState({
                        expandSubscriptions: false
                    })
                });
        } else {
            this.setState({
                expandSubscriptions: !this.state.expandSubscriptions
            })
        }
    }

    activateUser(id) {
        if (!this.props.isActive) {
            UserService.getUserById(id)
                .then(user => {
                    user = {...user, isEnabled: true};
                    UserService.editUser(user, user.id)
                        .then(u => {
                            this.props.refreshUsers();
                        })
                        .catch(err => {
                            throw new Error(err);
                        });
                })
                .catch(err => {
                });
        }
    }

    onDelete() {
        UserService.deleteUser(this.state.selectedUser.id)
            .then(response => {
                this.props.getAndProcessUsers();
                this.setState({
                    selectedUser: null,
                    deleteModalIsOpen: false
                });
            })
            .catch(err => {
                this.setState({
                    deleteError: true,
                    errorMessage: err.data.error
                })
            })
    }

    render() {
        if (!this.props.user) return null;
        let {user} = this.props;
        return(
            <div className="entity-list__item user-item">
                <div className="user__row">
                    <div className="user__cell alpha">
                        <div className="user-type__icon user"></div>
                    </div>
                    <div className="user__cell beta">
                        <div className="user__info">
                            <h2 className="user__name admin">{user.fullName}</h2>
                            <ul className="user__details">
                                <li>Username: <strong>{user.name}</strong></li>
                                <li>Role(s): <strong>Admin</strong></li>
                            </ul>
                            <div className="user__subs">
                                <a onClick={e => {e.preventDefault(); this.triggerSubscriptionRetrieval(user.id)}}
                                   className={`subs-count subs-add-trigger ${this.state.expandSubscriptions ? 'is-active' : ''}`}>
                                    Assigned Subscriptions <span className="roboto">({user.subscriptionCount})</span>
                                </a>
                                <ul className="subs-list">
                                    {this.state.userDetails && this.state.userDetails.subscriptions.length && this.state.userDetails.subscriptions.map(subscription => {
                                        return <li key={subscription.id}>{subscription.name}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="user__cell gamma">
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['UserEditUser']}
                            oneperm
                        >
                            {this.props.isActive && <a className="user__action" onClick={(e) => {e.preventDefault(); this.props.triggerEditUserModal(user.id)}}>Edit</a>}
                            {!this.props.user.roles.includes('Administrator') && <a className="user__action" onClick={(e) => {e.preventDefault(); this.setState({deleteModalIsOpen: true, selectedUser: user})}}>Delete</a>}
                            {!this.props.isActive && <a className="user__action" onClick={(e) => {e.preventDefault(); this.activateUser(user.id)}}>Activate</a>}
                        </PermissibleRender>
                    </div>
                </div>
                <DeleteModal error={this.state.deleteError} errorMessage={this.state.errorMessage || 'User can\'t be deleted because there are one or more transactions associated with this user.'} data={this.state.selectedUser ? this.state.selectedUser.name : null} isOpen={this.state.deleteModalIsOpen} closeModal={() => this.setState({deleteModalIsOpen: false, selectedUser: null, deleteError: false})} onDelete={this.onDelete}/>

            </div>
        )
    }

}