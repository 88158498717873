import React, {Component} from 'react';
import onClickOutside from 'react-onclickoutside';

class InlineMenu extends Component {

    constructor(props) {
        super(props);
    }

    handleClickOutside(evt) {
        if (this.props.menuIsOpen) {
            this.props.closeMenu();
        }
    }

    render() {
        return (
            <div className="filter__container">
                {this.props.children}
            </div>
        )
    }

}

export default onClickOutside(InlineMenu);