import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from "react-day-picker/moment";
import moment from "moment";

export const DayPicker = (field) => (
    <div className="control has-icons-left">
        <DayPickerInput
            inputProps={{disabled: field.disabled || false, className:`date ${!field.meta.error && field.meta.warning ? 'is-warning' : field.meta.touched && field.meta.error ? 'is-danger' : ''}`}}
            value={field.input.value.length > 0 ? moment(field.input.value).utc().format('YYYY/MM/DD') : ""}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={field.dayPickerProps}
            placeholder={field.placeholder}
            onDayChange={field.input.onChange}
        />
        <span className="icon is-small is-left">
           <span className="custom-icon date"></span>
        </span>
        {(field.meta.touched && field.meta.error && <p className="help is-danger">{field.meta.error}</p>)}
    </div>
)