import React, {Component} from 'react';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import Modal from 'react-modal';

import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';
import UserService from "../../services/api/Users";
import UserItem from "./Users/UserItem";
import * as qs from 'query-string';
import {isEmpty, isEqual, get} from 'lodash';
import {ReactComponent as ClearIcon} from "../../images/icons/i_clear.svg";

export default class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addUserModalIsOpen: false,
            editUserModalIsOpen: false,
            usersCount: 0,
            currentUserEditId: null,
            users: [],
            activeUsers: [],
            inactiveUsers: [],
            filteredRow: undefined
        };

        this.getAndProcessUsers = this.getAndProcessUsers.bind(this);
    }

    async getUserData(id) {
        return UserService.getUserById(id);
    }

    getAndProcessUsers() {
        let self = this;
        UserService.getUsers().then(users => {
                users = users.filter(user => user.accountId === this.props.account.id)
                            .map(u => {
                                u.roles = u.roleNames;
                                return u;
                            });

                let activeUsers = users.filter(user => user.isEnabled === true),
                    inactiveUsers = users.filter(user => user.isEnabled === false);

                self.setState({
                    users: users,
                    activeUsers,
                    inactiveUsers,
                    usersCount: users.length
                }, () => {
                    const queryString = qs.parse(this.props.location.search);
                    const row = self.state.users.filter((user) => user.id === queryString?.user_id)
                    if (!isEmpty(row)) {
                        if (get(row, '[0].isEnabled', false)) {
                            self.setState({
                                ...this.state,
                                activeUsers: row,
                                inactiveUsers: [],
                                filteredRow: get(row, '[0]', undefined)
                            })
                        } else {
                            self.setState({
                                ...this.state,
                                activeUsers: [],
                                inactiveUsers: row,
                                filteredRow: get(row, '[0]', undefined)
                            })
                        }

                    }
                });
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    componentDidMount() {
        Modal.setAppElement('body');
        this.getAndProcessUsers();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.editUserModalIsOpen !== prevState.editUserModalIsOpen) {
            if (this.state.editUserModalIsOpen === false) {
                this.getAndProcessUsers();
            }
        }

        if (this.state.addUserModalIsOpen !== prevState.addUserModalIsOpen) {
            if (this.state.addUserModalIsOpen === false) {
                this.getAndProcessUsers();
            }
        }
        if (!isEmpty(this.props.location.search)) {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                const queryString = qs.parse(this.props.location.search);
                const row = this.state.users.filter((user) => user.id === queryString?.user_id)
                if (get(row, '[0].isEnabled', false)) {
                    this.setState({
                        ...this.state,
                        activeUsers: row,
                        inactiveUsers: [],
                        filteredRow: get(row, '[0]', undefined)
                    })
                } else {
                    this.setState({
                        ...this.state,
                        activeUsers: [],
                        inactiveUsers: row,
                        filteredRow: get(row, '[0]', undefined)
                    })
                }
            }
        } else {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                if (isEmpty(this.props.location.search)) {
                    let activeUsers = this.state.users.filter(user => user.isEnabled === true),
                        inactiveUsers = this.state.users.filter(user => user.isEnabled === false);
                    this.setState({
                        ...this.state,
                        activeUsers,
                        inactiveUsers,
                        filteredRow: undefined
                    })
                }
            }
        }
    }

    triggerAddUserModal() {
        this.setState({
            addUserModalIsOpen: !this.state.addUserModalIsOpen,
            currentUserEditId: null
        })
    }

    triggerEditUserModal(currentUserEditId) {
        this.setState({
            editUserModalIsOpen: !this.state.editUserModalIsOpen,
            currentUserEditId
        })
    }

    render() {
        return (
            <section className="users">

                <Modal
                    isOpen={this.state.addUserModalIsOpen}
                    contentLabel="Add User"

                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}

                    onRequestClose={() => this.setState({addUserModalIsOpen: false})}
                >
                    <AddUser account={this.props.account} userId={this.state.currentUserEditId} triggerAddUserModal={this.triggerAddUserModal.bind(this)}/>

                </Modal>

                <Modal
                    isOpen={this.state.editUserModalIsOpen}
                    contentLabel="Add User"

                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}

                    onRequestClose={() => this.setState({editUserModalIsOpen: false})}
                >
                    <EditUser userClaims={this.props.userClaims} account={this.props.account} userId={this.state.currentUserEditId} triggerEditUserModal={this.triggerEditUserModal.bind(this)}/>

                </Modal>

                <div className="wrapper">

                    <div className="page__head">

                        <h2 className="page__title small">Users (<span className="roboto">{this.state.usersCount}</span>)</h2>
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['UserAddUser']}
                            oneperm
                        >
                            <a href="" className="cta mobile-short" onClick={(e) => {e.preventDefault(); this.triggerAddUserModal()}}>
                                <span className="mobile-only">+ Add New</span>
                                <span className="tablet-and-above">+ Add New User</span>
                            </a>
                        </PermissibleRender>

                    </div>

                    {this.state.activeUsers.length > 0 &&
                        <div className="entity-list user-list">
                            <div className="entity-list__group-head flex items-center justify-between">
                                <div className="group-head__name">Active Users</div>
                                <div className="">
                                    <div className="">
                                        {this.state.filteredRow && (
                                            <div className={'px-4 py-2 bg-white border text-md flex flex-row items-center justify-center mt-6'}>
                                                <div className={'pr-2  text-gray-500'}>{this.state.filteredRow.name}</div>
                                                <ClearIcon className={'cursor-pointer'} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(`/account/${this.props.account.id}/users`)
                                                }}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {this.state.activeUsers.map(user => {
                                return <UserItem getAndProcessUsers={this.getAndProcessUsers} isActive={true} userClaims={this.props.userClaims} triggerEditUserModal={this.triggerEditUserModal.bind(this)} key={user.id} user={user}/>
                            })}
                        </div>
                    }

                    {this.state.inactiveUsers.length > 0 &&
                        <div className="entity-list user-list">

                            <div className="entity-list__group-head  flex items-center justify-between">
                                <div className="group-head__name">Inactive Users</div>
                                <div className="">
                                    <div className="">
                                        {this.state.filteredRow && (
                                            <div className={'px-4 py-2 bg-white border text-md flex flex-row items-center justify-center mt-6'}>
                                                <div className={'pr-2  text-gray-500'}>{this.state.filteredRow.name}</div>
                                                <ClearIcon className={'cursor-pointer'} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push(`/account/${this.props.account.id}/users`)
                                                }}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {this.state.inactiveUsers.map(user => {
                                return <UserItem getAndProcessUsers={this.getAndProcessUsers} isActive={false} userClaims={this.props.userClaims} refreshUsers={this.getAndProcessUsers.bind(this)} key={user.id} user={user}/>
                            })}
                        </div>
                    }
                    <div className="entity-list bottom-spacer"></div>
                </div>

            </section>

        );
    }
}