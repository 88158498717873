import React, {Component, Fragment} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form';
import Tooltip from 'rc-tooltip';
import UserService from "../../../services/api/Users";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {InputField} from "../../../inputs/standard";
import {
    passwordComplexity,
    passwordsMatch,
    required
} from "../../../inputs/validations";
import RolesService from "../../../services/api/Roles";
import SubscriptionAssignModal from "./SubscriptionAssignModal";
import SubscriptionMobileAssignModal from "./SubscriptionMobileAssignModal";
import authenticator from 'authenticator';
import QRCode from 'qrcode.react';
import SwitchButton from "lyef-switch-button";
import {RadioButtons} from "../../../inputs/radioButtons";
import {queryClient} from "../../../App";
import PasswordRules from "../../../components/PasswordRules";


/**
 * Please do refactor this :)
 */

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetails: null,
            roles: null,
            mappedRoles: new Map(),
            subscriptionAssignOpen: false,
            subscriptionAssignOpenMobile: false,
            mfaEnabled: window.env.MFA_REQUIRED,
            showMFAError: false,
            showMFARequiredError: false,
            otpAuthURI: null,
            mfaSecret: null,
            mfa: {
                enabled: false,
                totpEnabled: false,
                webauthnEnabled: false
            }
        }
        this.setMFASecret = this.setMFASecret.bind(this);
        this.addYubiKeyRegistration = this.addYubiKeyRegistration.bind(this);
        this.removeYubiKeyRegistration = this.removeYubiKeyRegistration.bind(this);
    }

    componentDidMount() {
        if (this.props.userId) {
            this.getUserDetailsForEditing(this.props.userId);
        }
    }

    generateRandomString() {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        array = array.map(x => validChars.charCodeAt(x % validChars.length));
        return String.fromCharCode.apply(null, array);
    }

    setMFASecret() {
        if (this.props.formValues && this.props.formValues.name) {
            let secretKey = this.generateRandomString();
            this.setState({
                mfaSecret: secretKey.replace(/\W/g, '').toLowerCase(),
                showMFAError: false,
                showMFARequiredError: false
            })
            let otpAuthURI = authenticator.generateTotpUri(secretKey, this.props.formValues.name, "AcuantAdminPortal", "SHA256", 6, 30);
            this.setState({
                otpAuthURI
            })
        }
    }

    getUserDetailsForEditing(id) {
        UserService.getUserById(id)
            .then(userDetails => {
                this.setState({
                    userDetails,
                    mfaEnabled: userDetails.credentialType > 0
                }, () => {
                    this.props.initialize(userDetails);
                    this.getUserRoles();
                })
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    removeSubscription(id) {
        let subscriptions = this.state.userDetails.subscriptions;
        subscriptions = subscriptions.filter(subscription => subscription.id !== id);
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                subscriptions
            }
        }, () => {
            this.props.change('subscriptions', subscriptions);
        });
    }

    setMappedRoles() {
        let mappedRoles = new Map();
        this.state.roles.map(role => {
            mappedRoles.set(role, false);
            this.state.userDetails.roles.map(r => {
                if (r.id === role.id) {
                    mappedRoles.set(role, true);
                }
            });
        });
        this.setState({
            mappedRoles
        })
    }

    getUserRoles() {
        RolesService.getRoles()
            .then(roles => {
                let mappedRoles = new Map();
                roles.map(role => {
                    mappedRoles.set(role, false);
                    this.state.userDetails.roles.map(r => {
                        if (r.id === role.id) {
                            mappedRoles.set(role, true);
                        }
                    });
                });
                this.setState({
                    roles,
                    mappedRoles
                })
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    onRoleChange(event, role) {
        console.log(role, event.target.checked);
        if (!event.target.checked) {
            let roles = this.state.userDetails.roles;
            roles = roles.filter(r => r.id !== role.id);
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    roles
                }
            }, () => {
                this.props.change('roles', this.state.userDetails.roles);
                this.setMappedRoles();
            })
        } else {
            let roles = this.state.userDetails.roles;
            roles.push(role);
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    roles
                }
            }, () => {
                this.props.change('roles', this.state.userDetails.roles);
                this.setMappedRoles();
            })
        }
    }

    onSubmit() {
        let {formValues} = this.props;

        console.log(formValues);
        if (formValues.password === null) {
            delete formValues['password'];
            delete formValues['confirm_password'];
        }

        formValues.isMFAEnabled = parseInt(formValues.credentialType) > 0 && parseInt(formValues.credentialType) === 1;
        formValues.credentialType = parseInt(formValues.credentialType);

        if (formValues.isMFAEnabled) {
            UserService.editMFASecret({secret: this.state.mfaSecret, id: formValues.id}, formValues.id)
                .then(resp => {
                })
                .catch(err => {
                    console.error(err);
                })
        }

        return UserService.editUser(formValues, formValues.id)
            .then(data => {
                queryClient.refetchQueries(['users']);
                this.props.triggerEditUserModal();
            })
            .catch(err => {
                throw new SubmissionError({...err.data});
            });
    }

    onSubscriptionsSelect(subscriptions) {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                subscriptions: subscriptions
            },
            subscriptionAssignOpen: false,
            subscriptionAssignOpenMobile: false
        }, () => {
            this.props.change('subscriptions', subscriptions);
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formValues && this.props.formValues) {
            if (prevProps.formValues.name !== this.props.formValues.name) {
                if (parseInt(this.props.formValues['credentialType']) === 1) {
                    this.setMFASecret()
                }
            }

            if (parseInt(prevProps.formValues['credentialType']) !== parseInt(this.props.formValues['credentialType'])) {
                //console.log("AICI", prevProps.formValues['credentialType'])
                if (parseInt(this.props.formValues['credentialType']) === 1) {
                    this.setMFASecret()
                }
            }


        }
    }

    async addYubiKeyRegistration() {
        let self = this;
        let userReferenceId = this.state.userDetails.id;
        let displayName = this.state.userDetails.fullName;
        let credentialNickname = 'Acuant yubikey';
        let w = window.open(`${window.env.AUTHORITY_URL}/WebAuthn/AutoRegister?userId=${encodeURIComponent(userReferenceId)}&displayName=${encodeURIComponent(displayName)}&credentialNickname=${encodeURIComponent(credentialNickname)}`, '_blank', 'toolbar=0,location=0,menubar=0,modal=yes,width=600,height=600')
        window.addEventListener('message', function onMessage(e){
            if (e.data === 'success') {
                w.close();
                window.removeEventListener('message', onMessage);
                self.getUserDetailsForEditing(self.props.userId);
            }
        }, false)
    }

    async removeYubiKeyRegistration(id) {
        let self = this;
        let userReferenceId = this.state.userDetails.id;
        let displayName = this.state.userDetails.fullName;
        let w = window.open(`${window.env.AUTHORITY_URL}/WebAuthn/Deregister?userId=${encodeURIComponent(userReferenceId)}&displayName=${encodeURIComponent(displayName)}&id=${encodeURIComponent(id)}`, '_blank', 'toolbar=0,location=0,menubar=0,modal=yes,width=600,height=600')
        window.addEventListener('message', function onMessage(e){
            if (e.data === 'success') {
                w.close();
                window.removeEventListener('message', onMessage);
                self.getUserDetailsForEditing(self.props.userId);
            }
        }, false)
    }

    onMFAChange(e) {
        this.setState({
            mfaEnabled: e.target.checked
        });
        if (e.target.checked) {
            this.setMFASecret()
        } else {
            if (window.env.MFA_REQUIRED) {
                this.setState({
                    showMFARequiredError: true
                })
            }
            this.setState({
                showMFAError: true
            })
        }
    }

    render() {
        if (!this.state.userDetails) return null;
        if (!this.props.formValues) return null;
        if (!this.state.mappedRoles) return null;
        let canEditMfaRelatedFields = !((this.props.currentUserEmail === this.state.userDetails.name) && (this.state.userDetails && this.state.userDetails.credentials && this.state.userDetails.credentials.length > 0));
        //console.log("XXX", canEditUsername, (this.props.currentUserEmail === this.state.userDetails.name));
        let roles = [];
        this.state.mappedRoles.forEach((value, role) => {
            roles.push(
                <li key={role.id}>
                    <input checked={value} onChange={(e) => this.onRoleChange(e, role)} className="checkbox" type="checkbox" name={`role-${role.id}`} id={`role-${role.id}`}/>
                    <label htmlFor={`role-${role.id}`}>{role.name}</label>
                    <Tooltip placement="left" trigger="click" destroyTooltipOnHide={true} placement="right" overlay={role.description}>
                        <a href="#" className="tooltip-trigger info"></a>
                    </Tooltip>
                </li>
            )
        });
        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">Edit User</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerEditUserModal()}}></span>
                </div>
                <div className="e-modal__body">
                    <div className="f-container">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-name">Name</label>
                                        <Field
                                            component={InputField}
                                            validate={[required]}
                                            type="text"
                                            className='field'
                                            name="fullName"
                                            placeholder="Enter user name"
                                            alt="Enter user name"
                                            tabIndex="1"
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="user-email">Username</label>
                                        <Field
                                            component={InputField}
                                            validate={[required]}
                                            disabled={!canEditMfaRelatedFields}
                                            type="text"
                                            className='field'
                                            name="name"
                                            placeholder="Enter user username"
                                            alt="Enter user username"
                                            tabIndex="2"
                                        />
                                    </div>
                                </div>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['UserEditUser']}
                                    oneperm
                                >
                                    <div className="f-row">
                                        <div className="f-element">
                                            <label htmlFor="user-email">Reset user password</label>
                                            <Field
                                                component={InputField}
                                                validate={[passwordComplexity(), passwordsMatch()]}
                                                type="password"
                                                className='field'
                                                name="password"
                                                placeholder="Enter new password"
                                                alt="Enter user username"
                                                tabIndex="2"
                                            />
                                            <PasswordRules password={this.props.formValues?.password}/>
                                        </div>
                                    </div>
                                    <div className="f-row">
                                        <div className="f-element">
                                            <label htmlFor="user-email">Confirm new user password</label>
                                            <Field
                                                component={InputField}
                                                validate={[passwordsMatch()]}
                                                type="password"
                                                className='field'
                                                name="confirm_password"
                                                placeholder="Confirm new password"
                                                alt="Enter user username"
                                                tabIndex="2"
                                            />
                                        </div>
                                    </div>
                                </PermissibleRender>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Enable MFA</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <SwitchButton
                                                id={`credentialType`}
                                                isChecked={parseInt(this.props.formValues['credentialType'])}
                                                action={() => {
                                                    if (parseInt(this.props.formValues['credentialType']) > 0) {
                                                        this.props.change('credentialType', "0");
                                                    } else {
                                                        this.props.change('credentialType', "1");
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {this.props.formValues['credentialType'] > 0 &&

                                        <Fragment>
                                            <Field component={RadioButtons}
                                               style={{width: '100%'}}
                                               parentClassName={'custom-radio__container'}
                                               fieldName={'credentialType'}
                                               options={
                                                   [
                                                       {
                                                           id: "totp",
                                                           label: "TOTP Authenticator",
                                                           value: "1",
                                                       },
                                                       {
                                                           id: "webauthn",
                                                           label: "WebAuthn (FIDO2)",
                                                           value: "2",
                                                       },
                                                   ]
                                               }
                                            />
                                            {parseInt(this.props.formValues['credentialType']) === 1 && this.state.otpAuthURI &&
                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                                    <Fragment>
                                                        <QRCode value={this.state.otpAuthURI} />
                                                        <div style={{marginTop: 8}}>This code must be scanned using Google Authenticator on an iPhone only.</div>
                                                    </Fragment>
                                                </div>
                                            }
                                            {parseInt(this.props.formValues['credentialType']) === 2 &&
                                            <div className={'context'} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: 16}}>
                                                <Fragment>
                                                    <div className="context__cell alpha">
                                                        <span className="context__title">Assigned credentials</span>
                                                    </div>
                                                    <div className="context__cell beta">
                                                        <a onClick={(e) => {e.preventDefault(); this.addYubiKeyRegistration();}}
                                                           className="context__trigger">
                                                            + Register new credential
                                                        </a>
                                                    </div>
                                                </Fragment>
                                                <Fragment>
                                                    <div className="e-table" style={{padding: "16px 0 0 0"}}>
                                                        {this.state.userDetails.credentials.map(credential => {
                                                            return (
                                                                <div key={credential.id} className="e-table__row context child" style={{justifyContent: 'space-between'}}>
                                                                    <div className="e-table__cell alpha">
                                                                        <span className="e-table__name">{credential.nickname}</span>
                                                                    </div>
                                                                    <div className="e-table__cell control_cell">
                                                                        <a style={{color: canEditMfaRelatedFields ? '' : '#828282'}} className={`${canEditMfaRelatedFields ? '' : 'disabled'}`} onClick={e => {e.preventDefault(); canEditMfaRelatedFields && this.removeYubiKeyRegistration(credential.id);}}>Remove</a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                </Fragment>
                                            </div>
                                            }
                                        </Fragment>
                                    }
                                </div>
                                {/*<div className="f-row border-top">
                                    <h2 className="f-section__title ">Enable MFA</h2>
                                    <ul className="add-role-list">
                                        <li>
                                            <input checked={this.state.mfaEnabled} onChange={(e) => this.onMFAChange(e)} className="checkbox" type="checkbox" name={`mfa-enabled`} id={`mfa-enabled`}/>
                                            <label htmlFor={`mfa-enabled`}>MFA Enabled</label>
                                        </li>
                                        {this.state.showMFARequiredError && <div className={'f-element'}>
                                            <p className="help is-danger">MFA is required to log into this system. If you disable MFA for this user, they will be unable to log in.</p>
                                        </div>
                                        }
                                        {this.state.showMFAError && <div className={'f-element'}>
                                            <p className="help is-danger">If you disable MFA and later re-enable it, you will need to rescan the QR Code</p>
                                        </div>
                                        }
                                    </ul>

                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

                                    </div>
                                </div>*/}
                                <div className="f-row border-top">
                                    <h2 className="f-section__title ">User role</h2>
                                    <ul className="add-role-list">
                                        {roles}
                                    </ul>
                                </div>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">Assigned subscriptions</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <a className={`context__trigger asign-new-sub__trigger ${this.state.subscriptionAssignOpen ? 'is-active' : ''}`}>
                                                <span onClick={() => this.setState({subscriptionAssignOpenMobile: !this.state.subscriptionAssignOpenMobile})}  className="mobile-only">+ Assign new</span>
                                                <span onClick={() => this.setState({subscriptionAssignOpen: !this.state.subscriptionAssignOpen})}  className="tablet-and-above">+ Assign new subscription</span>
                                            </a>

                                            {this.state.subscriptionAssignOpen && <SubscriptionAssignModal selectedSubscriptions={this.state.userDetails.subscriptions} onSubscriptionsSelect={this.onSubscriptionsSelect.bind(this)} accountId={this.props.account.id} />}
                                        </div>
                                        {this.state.subscriptionAssignOpenMobile && <SubscriptionMobileAssignModal selectedSubscriptions={this.state.userDetails.subscriptions} onSubscriptionsSelect={this.onSubscriptionsSelect.bind(this)} accountId={this.props.account.id}/> }

                                    </div>
                                    <div className="e-table">
                                        {this.state.userDetails.subscriptions && this.state.userDetails.subscriptions.length > 0 && this.state.userDetails.subscriptions.map(subscription => {
                                            return (
                                                <div key={subscription.id} className="e-table__row">
                                                    <div className="e-table__cell alpha">
                                                        <span className="e-table__name">{subscription.name}</span>
                                                        <span className="e-table__data">ID: {subscription.id}</span>
                                                    </div>
                                                    <div className="e-table__cell beta">
                                                        <span className="e-table__data bold uppercase tiny vertically-centered">{subscription.accountName}</span>
                                                    </div>
                                                    <div className="e-table__cell control_cell">
                                                        <a onClick={e => {e.preventDefault(); this.removeSubscription(subscription.id)}}>Remove</a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row border-top">
                                    <div className="context">
                                        <div className="context__cell alpha">
                                            <span className="context__title">{this.props.isEnabled ? 'Disable' : 'Enable'} this user?</span>
                                        </div>
                                        <div className="context__cell beta">
                                            <a onClick={(e) => {e.preventDefault(); this.props.change('isEnabled', !this.props.isEnabled);}}
                                               className="context__trigger">
                                                {this.props.isEnabled ? 'Disable' : 'Enable'}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="f-row cta-container">
                                    <div className="f-element alpha">
                                        <button className={'cta'}>Save Changes</button>
                                    </div>
                                    <div className="f-element beta">
                                        <button onClick={e => {e.preventDefault(); this.props.triggerEditUserModal()}} className="light">Cancel</button>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>

                </div>

            </div>

        );
    }
}

EditUser = reduxForm({
    form: 'editUser',
    destroyOnUnmount: true
})(EditUser);

function mapStateToProps(state) {
    const selector = formValueSelector('editUser');
    return {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        formValues: getFormValues('editUser')(state),
        subscriptions: selector(state, 'subscriptions'),
        isEnabled: selector(state, 'isEnabled'),
        credentialType: selector(state, 'CredentialType') || 0,
        currentUserEmail: state.userProfile.name
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);