import request from '../shared/request';

function getTransactions(accountId, begin = null, end = null) {

    /**
     * Find a better way of doing this
     *
     */

    let url = `/api/transactions?accountId=${accountId}`;
    if (begin) {
        url += `&begin=${begin}`
    }
    if (end) {
        url += `&end=${end}`
    }

    return request({
        url: url,
        method: 'GET'
    })
}

const TransactionsService = {
    getTransactions
};

export default TransactionsService;