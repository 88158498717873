import React, {Component} from 'react';
import SubscriptionService from "../../../services/api/Subscriptions";


export default class SubscriptionAssignModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptions: [],
            initialSubscriptions: [],
            currentAccountSubscriptions: [],
            otherAccountsSubscriptions: [],
            selectedSubscriptions: []
        };

        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        SubscriptionService.getAccountSubscription()
            .then(subscriptions => {
                this.setState({
                    initialSubscriptions: subscriptions
                }, () => {
                    this.processSubscriptions(subscriptions);
                });
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    processSubscriptions(subscriptions) {
        let currentAccountSubscriptions = subscriptions.filter(subscription => subscription.accountId === this.props.accountId),
            otherAccountsSubscriptions = subscriptions.filter(subscription => subscription.accountId !== this.props.accountId);
        this.setState({
            subscriptions,
            currentAccountSubscriptions,
            otherAccountsSubscriptions,
            selectedSubscriptions: this.props.selectedSubscriptions || []
        })
    }

    onSubscriptionCheck(event, subscription) {
        if (event.target.checked) {
            let selectedSubscriptions = this.state.selectedSubscriptions;
            selectedSubscriptions.push(subscription);
            this.setState({
                selectedSubscriptions
            })
        } else {
            let selectedSubscriptions = this.state.selectedSubscriptions.filter(sub => sub.id !== subscription.id);
            this.setState({
                selectedSubscriptions
            });
        }
    }

    selectNewSubscriptions(event) {
        event.preventDefault();
        if (this.state.selectedSubscriptions.length > 0) {
            this.props.onSubscriptionsSelect(this.state.selectedSubscriptions);
        }
    }

    isChecked(subscription) {
        return !!this.state.selectedSubscriptions.filter(s => s.id === subscription.id).length > 0;
    }

    onSearchChange(e) {
        e.preventDefault();
        let subscriptions = this.state.initialSubscriptions.filter(s => s.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
        this.processSubscriptions(subscriptions);
    }

    render() {
        return (
            <div className="asign-new-sub tablet-and-above">
                <h3 className="asign-new-sub__title">Assign new subscription</h3>
                <input onChange={this.onSearchChange} type="text" className="asign-new-sub__search" placeholder="Search subscriptions" />
                <div className="asign-new-sub__list">
                    <div className="asign-new-sub__head">from current account</div>
                    {this.state.currentAccountSubscriptions.map(subscription => {
                        return <div key={subscription.id} className="asign-new-sub__item">
                            <input checked={this.isChecked(subscription)} onChange={event => this.onSubscriptionCheck(event, subscription)} type="checkbox" className="checkbox" name={`${subscription.id}`} id={`${subscription.id}`} />
                            <label htmlFor={`${subscription.id}`}>
                            <span className="asign-new-sub__details">
                                <span className="asign-new-sub__item-name">{subscription.name}</span>
                                <span className="asign-new-sub__item-id">ID: {subscription.id}</span>
                            </span>
                            </label>
                        </div>
                    })}
                    <div className="asign-new-sub__head">from other accounts</div>
                    {this.state.otherAccountsSubscriptions.map(subscription => {
                        return <div key={subscription.id} className="asign-new-sub__item">
                            <input checked={this.isChecked(subscription)} onChange={event => this.onSubscriptionCheck(event, subscription)} type="checkbox" className="checkbox" name={`${subscription.id}`} id={`${subscription.id}`} />
                            <label htmlFor={`${subscription.id}`}>
                            <span className="asign-new-sub__details">
                                <span className="asign-new-sub__item-name">{subscription.name}</span>
                                <span className="asign-new-sub__item-id">ID: {subscription.id}</span>
                            </span>
                            </label>
                        </div>
                    })}
                </div>
                <button onClick={this.selectNewSubscriptions.bind(this)}>Assign ({this.state.selectedSubscriptions.length})</button>
            </div>
        )
    }
}