import React, {Component, Fragment} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues} from 'redux-form';
import AccountService from "../../services/api/Account";
import TransactionsService from "../../services/api/Transactions";
import ReportItem from "./Reports/ReportItem";
import ReactToPrint from "react-to-print";
import {DayPicker} from "../../inputs/dayPicker";
import {endAfterBeginDate, isValidDate, required} from "../../inputs/validations";
import moment from "moment/moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialState: null,
            accounts: null,
            transactions: [],
            selectedAccounts: [],
            selectAllAccountsChecked: false,
            selectAccountsModalOpen: false,
            startDate: null,
            endDate: null
        }
    }

    componentWillMount() {
        AccountService.getAccount(this.props.account.id)
            .then(account => {
                let accounts = account.accounts;
                this.setState({
                    initialState: accounts,
                    accounts
                });
            })
            .catch(err => {
                throw new Error(err);
            });
        this.props.initialize({
            begin: "",
            end:  ""
        });
    }

    /*toggleAccount(event, selectedAccount) {
        if (event && event.target.checked) {
            let selectedAccounts = this.state.selectedAccounts,
                accounts = this.state.accounts.filter(account => selectedAccount !== account);
            selectedAccounts.push(selectedAccount);
            let selectAllAccountsChecked = false;
            if (this.state.selectedAccounts.length === this.state.initialState.length) {
                selectAllAccountsChecked = true;
            }
            this.setState({
                selectedAccounts,
                accounts,
                selectAllAccountsChecked
            });
        } else {
            let selectedAccounts = this.state.selectedAccounts;
            selectedAccounts = selectedAccounts.filter(item => item !== selectedAccount);
            let accounts = this.state.initialState.filter(account => {
                return !selectedAccounts.some(s => {
                    return s.id === account.id;
                })
            });
            this.setState({
                selectedAccounts,
                accounts,
                selectAllAccountsChecked: false
            });
        }
    }*/

    /*filterAccounts(e) {
        if (!e.target.value.length) {
            let initialState = this.state.initialState;
            let accounts = initialState.filter(account => {
                return !this.state.selectedAccounts.some(s => {
                    return s.id === account.id;
                })
            });
            this.setState({accounts})
        } else {
            let accounts = this.state.accounts;
            accounts = accounts.filter(account => account.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
            this.setState({
                accounts
            });
        }
    }*/

    /*selectAllAccounts(event) {
        if (event && event.target.checked) {
            this.setState({
                selectedAccounts: [{...this.props.account}],
                accounts: [],
                selectAllAccountsChecked: true
            })
        } else {
            this.setState({
                selectedAccounts: [],
                accounts: this.state.initialState,
                selectAllAccountsChecked: false
            })
        }
    }*/

    generateReport() {
        let {formValues} = this.props;
        let begin = typeof formValues.begin === "object" ? moment(formValues.begin).utc(formValues.begin).subtract(11.99, "hours").format() : undefined;
        let end = typeof formValues.end === "object" ? moment(formValues.end).utc(formValues.end).add(11.99, "hours").format() : undefined;
        this.setState({
            startDate: moment(formValues.begin).utc().format("MMMM DD, YYYY"),
            endDate: moment(formValues.end).utc().format("MMMM DD, YYYY")
        });
        TransactionsService.getTransactions(this.props.account.id, begin, end)
            .then(reports => {
                console.log(reports);
                let transactions = [];
                reports.map(report => {
                    if (report.transactions.length > 0) {
                        transactions.push(report);
                    }
                });
                this.setState({
                    transactions
                })
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    render() {
        if (!this.state.accounts) return null;
        return (
            <section className="reports">
                <div className="wrapper">
                    <div className="page__head">
                        <h2 className="page__title small">Reports</h2>
                    </div>
                    <div className="f-container reports-form">
                        <form onSubmit={() => {}}>
                            <div className="f-section">
                                <div className="f-row border-bottom reports-form__container">
                                    <div className="f-element alpha">
                                        <label htmlFor="pick-month">Start date</label>
                                        <Field
                                            autocomplete="off"
                                            component={DayPicker}
                                            placeholder={"MM/DD/YYYY"}
                                            name="begin"
                                            alt="Select start date"
                                            tabIndex="1"
                                            validate={[isValidDate()]}
                                            dayPickerProps={{
                                                disabledDays: {after: moment().subtract(1, 'days')._d}
                                            }}
                                        />
                                    </div>
                                    <div className="f-element beta"></div>
                                    <div className="f-element gamma">
                                        <label htmlFor="pick-a-span-of-months">End date</label>
                                        <Field
                                            autocomplete="off"
                                            component={DayPicker}
                                            validate={[endAfterBeginDate(), isValidDate()]}
                                            placeholder={"MM/DD/YYYY"}
                                            name="end"
                                            alt="Select end date"
                                            tabIndex="2"
                                            dayPickerProps={{
                                                disabledDays: {after: moment().subtract(1, 'days')._d}
                                            }}
                                        />
                                    </div>
                                    {/*<div className="f-element epsilon">
                                        <div className="entity-select__component">
                                            <label htmlFor="">Select Accounts</label>
                                            <a onClick={e => {e.preventDefault(); this.setState({selectAccountsModalOpen: !this.state.selectAccountsModalOpen})}} href="#" className="sim-select">Select Accounts</a>
                                            <div className={`entity-select__container ${this.state.selectAccountsModalOpen ? 'is-active' : ''}`}>
                                                <div className="entity-select__col-left">
                                                    <h3 className="entity-select__title">Choose Accounts</h3>
                                                    <div className="f-element search">
                                                        <input type="text" onChange={this.filterAccounts.bind(this)}/>
                                                        <input className="search-submit search-icon" type="submit" value="" />
                                                    </div>
                                                    <ul className="entity__list">
                                                        {this.state.accounts.map(account => {
                                                            return (
                                                                <li key={account.id}>
                                                                    <div className="entity__details">
                                                                        <strong>{account.name}</strong>
                                                                        <span>Id: {account.id}</span>
                                                                    </div>
                                                                    <div className="entity__select">
                                                                        <input onClick={event => this.toggleAccount(event, account)} type="checkbox" className="checkbox" name={`option-${account.id}`} id={`option-${account.id}`}/>
                                                                        <label htmlFor={`option-${account.id}`}></label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <div className="entity__select-all">
                                                        <input checked={this.state.selectAllAccountsChecked} onClick={event => this.selectAllAccounts(event)} type="checkbox" className="checkbox" name="select-all" id="select-all"/>
                                                        <label htmlFor="select-all">{this.props.account.name} (parent account)</label>
                                                    </div>
                                                </div>
                                                <div className="entity-select__col-right">
                                                    <h4 className="entity-select__selected-title">Selected</h4>
                                                    <ul className="entity__selected-list">
                                                        {this.state.selectedAccounts.map(account => {
                                                            return (
                                                                <li key={account.id}>
                                                                    <div className="selected__details">
                                                                        <strong>{account.name}</strong>
                                                                    </div>
                                                                    <div className="selected__control">
                                                                        <input readOnly onClick={event => this.toggleAccount(event, account)} type="checkbox" checked className="checkbox remove" id="selected-one" name="selected-one"/>
                                                                        <label htmlFor="selected-one"></label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <div className="mobile-only">
                                                        <a onClick={e => {e.preventDefault(); this.setState({selectAccountsModalOpen: false})}} href="#" className="cta outline red">Select these accounts ({this.state.selectedAccounts.length})</a>
                                                    </div>
                                                    <div className="tablet-and-above selected-cta">
                                                        <a onClick={e => {e.preventDefault(); this.setState({selectAccountsModalOpen: false})}} href="#" className="cta">Select accounts ({this.state.selectedAccounts.length})</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="f-element delta">
                                        <button onClick={this.props.handleSubmit(this.generateReport.bind(this))}>Create Transaction Report</button>
                                    </div>

                                </div>

                            </div>

                        </form>

                    </div>

                    {this.state.transactions.length > 0 &&
                        <Fragment>
                            <div className="rep-head">
                                <h3 className="rep-head__title">Acuant Connect Transaction Report</h3>
                                <ReactToPrint
                                    trigger={() => <a className="rep-head__control">Download report as pdf</a>}
                                    content={() => this.componentRef}
                                />
                            </div>
                            <p>{this.state.startDate} through {this.state.endDate}</p>
                        </Fragment>
                    }


                    <div className="rep__list" ref={componentRef => this.componentRef = componentRef}>
                        {this.state.transactions.length > 0 &&
                            <div className="rep-head">
                                <h3 className="rep-head__title">Acuant Connect Transaction Report</h3>
                                <p>{this.state.startDate} through {this.state.endDate}</p>
                            </div>}
                        {this.state.transactions.length > 0 && this.state.transactions.map(report => {
                            return <ReportItem key={report.account.id + "-" +(new Date()).getTime()} report={report}/>
                        })}
                    </div>
                    {this.state.transactions.length > 0 &&
                        <div className="rep-head">
                            <a className="rep-head__control">Download report as pdf</a>
                        </div>
                    }

                </div>

            </section>

        );
    }
}

Reports = reduxForm({
    form: 'reports'
})(Reports);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('reports')(state)
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Reports);
