import React, {Component} from 'react';
import moment from 'moment';
import AccountChild from './Child'
import AccountService from "../../../services/api/Account";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import CustomModal from "../../shared/Modal";
import DeleteModal from "../../shared/DeleteModal";
import InlineMenu from "../../shared/InlineMenu";

export default class AccountParent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountToggle: false,
            deleteModalIsOpen: false,
            selectedAccount: null,
            deleteError: false,
            accountChildren: null
        };

        this.onDelete = this.onDelete.bind(this);
        this.onAccountDelete = this.onAccountDelete.bind(this);
    }

    expandAndGetAccounts(id) {
        this.setState({
            accountToggle: !this.state.accountToggle
        }, () => {
            if (this.state.accountToggle) {
                AccountService.filterByAccount(id)
                    .then(accounts => {
                        this.setState({
                            accountChildren: accounts.filter(account => account.id !== id)
                        })
                    })
                    .catch(err => {
                        throw new Error(err);
                    })
            }
        })
    }

    onDelete() {
        AccountService.deleteAccount(this.state.selectedAccount.id)
            .then(response => {
                this.props.onAccountDelete(this.state.selectedAccount.id);
                this.setState({
                    selectedAccount: null,
                    deleteModalIsOpen: false
                });
            })
            .catch(err => {
                this.setState({
                    deleteError: true
                })
                console.log(err);
            })
    }

    onAccountDelete(accountId) {
        let accountChildren = JSON.parse(JSON.stringify(this.state.accountChildren));
        this.props.getAccounts();
        accountChildren = accountChildren.filter(account => account.id !== accountId);
        this.setState({
            accountChildren
        })
    }

    navigateToAccount(id) {
        let {history} = this.props;
        history.push(`/account/${id}/subscriptions`);
    }

    render() {
        let {account} = this.props;
        if (!account) return null;
        let isRoot = account.id === this.props.userProfile.accountId;
        return (
            <div className={`account__item ${isRoot ? 'white' : ''}`}>
                <div className="account__row">
                    <div className="account__cell alpha">
                        {!isRoot && account.accountCount > 0 &&
                        <a className={`account__toggle ${this.state.accountToggle ? 'close' : 'open'}`}
                           onClick={this.expandAndGetAccounts.bind(this, account.id)}></a>}
                    </div>
                    <div className="account__cell beta">
                        {!isRoot && account.accountCount > 0 &&
                            <a className={`account__toggle mobile-only ${this.state.accountToggle ? 'close' : 'open'}`}
                            onClick={this.expandAndGetAccounts.bind(this, account.id)}></a>}
                        <h2 className="account__name" onClick={this.navigateToAccount.bind(this, account.id)}>{account.name} {isRoot &&
                        <span className="root__account">ROOT</span>}</h2>
                        {!isRoot &&
                            <ul className="account__details">
                                <li>Customer ID: <strong className="roboto">{account.customerId}</strong></li>
                                <li>Created on: <strong className="roboto">{moment(account.createdDate).format("MMM DD, YYYY - HH:mm:ss")}</strong></li>
                            </ul>
                        }
                    </div>
                    <div className="account__cell gamma">
                        <span className="value-tablet"><span className="roboto">{account.accountCount || 0}</span> child accounts</span>
                        <span className="value-mobile">Child Accounts ({account.accountCount || 0})</span>
                    </div>
                    <div className="account__cell delta">
                        <span className="value-tablet"><span className="roboto">{account.subscriptionCount || 0}</span> subscriptions</span>
                        <span className="value-mobile">Subscriptions ({account.subscriptionCount || 0})</span>
                    </div>
                    <PermissibleRender
                        userPermissions={this.props.userClaims}
                        requiredPermissions={['AccountEditAccount']}
                        oneperm
                    >
                        <div className="account__cell epsilon">
                            <a className="account____action" onClick={() => this.props.triggerEditAccountModal(account.id)}>Edit</a>
                            {(!isRoot && account.accountCount === 0) && <a className="account____action" onClick={() => this.setState({deleteModalIsOpen: true, selectedAccount: account})}>Delete</a>}
                        </div>
                    </PermissibleRender>
                </div>
                {!isRoot &&
                    this.state.accountToggle &&
                    account.accountCount > 0 &&
                    this.state.accountChildren &&
                    this.state.accountChildren.map(childAccount => <AccountChild onAccountDelete={this.onAccountDelete} triggerEditAccountModal={this.props.triggerEditAccountModal} history={this.props.history} key={childAccount.id} account={childAccount}/>)
                }
                {this.state.deleteModalIsOpen && <DeleteModal error={this.state.deleteError} errorMessage={'Account can\'t be deleted because there are one or more transactions associated with this account.'} data={this.state.selectedAccount ? this.state.selectedAccount.name : null} isOpen={this.state.deleteModalIsOpen} closeModal={() => this.setState({deleteModalIsOpen: false, selectedAccount: null, deleteError: false})} onDelete={this.onDelete}/>}
            </div>
        )
    }

}