export function configReducer(state = [], action) {
    switch (action.type) {
        case '@@api-set-data/SET_CLAIMS':
            return {
                ...state,
                claims: [...action.payload]
            };
        default:
            return state;
    }
}

export function userClaims(state = [], action) {
    switch (action.type) {
        case '@@acuant/POPULATE_USER_CLAIMS':
            return [
                ...action.payload
            ];
        default:
            return state;
    }
}

export function requestReducer(state = {}, action) {
    switch (action.type) {
        case '@@acuant/TICK':
            return {
                ...state,
                updatedAt: new Date()
            };
        default:
            return state;
    }


}