import React, {Component} from 'react';
import userManager from "./utils/userManager";

export default class IdleModalComponent extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.intervalHandle = null;
        this.tick = this.tick.bind(this);
        this.secondsRemaining = 60;
    }

    componentDidMount() {
        this.startCountDown();
    }

    startCountDown() {
        this.intervalHandle = setInterval(this.tick, 1000)
    }

    tick()  {
        if (this.secondsRemaining === 0) {
            clearInterval(this.intervalHandle);
            this.props.closeModal();
            return userManager.signoutRedirect();
        }
        this.secondsRemaining--;
        this.forceUpdate()
    }

    closeModal() {
        clearInterval(this.intervalHandle);
        this.props.closeModal();
    }

    render() {
        return (
            <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', color: 'rgba(0, 0, 0, 0.8)', fontSize: 18, lineHeight: 'normal', fontWeight: 700, height: '25%', alignItems: 'flex-end'}}>You've been inactive for {window.env.TIMEOUT_IDLE - 1} minutes</div>
                <div style={{height: '50%', padding: 16, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                    <div style={{color: 'rgba(0, 0, 0, 0.8)', fontSize: 14, lineHeight: 'normal', fontWeight: 700}}>You will be logged out in</div>
                    <div style={{fontSize: 36, lineHeight: 'normal', fontWeight: 700, marginTop: 16, color: '#D52B1E'}}>00:{this.secondsRemaining >= 10 ? this.secondsRemaining : `0${this.secondsRemaining}`}</div>
                </div>
                <div style={{height: '25%', width: '50%'}}><a onClick={this.closeModal} href="#" className="cta"><span className="tablet-and-above">I'm still here</span></a></div>

            </div>
        )
    }
}