import React, {Component, Fragment} from 'react';
import moment from "moment/moment";

export default class AccountPlaceholder extends Component {
    render() {
        let {count} = this.props;
        let rows = [];
        for (let i = 0; i < count; i++) {
            rows.push(
                <div key={i} className={`account__item`}>
                    <div className="account__row">
                        <div className="account__cell alpha">
                            <a className={`account__toggle loading-placeholder`}></a>
                        </div>
                        <div className="account__cell beta">
                            <h2 className="account__name loading-placeholder"></h2>
                            <ul className="account__details">
                                <li className='loading-placeholder'></li>
                                <li className='loading-placeholder'></li>
                            </ul>
                        </div>
                        <div className="account__cell gamma">
                            <span className="value-tablet loading-placeholder"></span>
                            <span className="value-mobile loading-placeholder"></span>
                        </div>
                        <div className="account__cell delta">
                            <span className="value-tablet loading-placeholder"></span>
                            <span className="value-mobile loading-placeholder"></span>
                        </div>
                        <div className="account__cell epsilon">
                            <a className="account____action loading-placeholder"></a>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <Fragment>
                {rows}
            </Fragment>
        )
    }
}