import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import './styles/main.css';
import App from './App';
import {unregister} from './registerServiceWorker';
import {persistor, store, history } from './store';

ReactDOM.render(
    <App routerHistory={history} store={store} persistor={persistor}/>,
    document.getElementById('root')
);
unregister();
