import React, {Component} from 'react';
import SwitchButton from 'lyef-switch-button';
import {Field, reduxForm, formValueSelector, getFormValues} from 'redux-form';
import {InputField} from "../../../inputs/standard";
import {required} from "../../../inputs/validations";
import {connect} from "react-redux";
import RolesService from "../../../services/api/Roles";
import RoleCategory from './RoleCategory';
import AccountService from "../../../services/api/Account";
import {SelectInput} from "../../../inputs/select";

class AddRole extends Component {

    constructor(props) {
        super(props);

        let mappedRoleDetails = new Map();
        this.props.claims.map(claim => {
            mappedRoleDetails.set(claim, false);
        });

        this.state = {
            accounts: [],
            mappedRoleDetails: mappedRoleDetails
        }
    }

    changeRoleValue(mappedRoleDetails) {
        let claims = [];
        mappedRoleDetails.forEach((value, claim) => {
            if (value) {
                claims.push(claim);
            }
        });
        this.setState({
            mappedRoleDetails
        })
    }

    onSubmit() {
        let {formValues} = this.props;
        formValues.accountId = formValues.accountId.value;
        RolesService.createRole(formValues)
            .then(role => {
                console.log(role)
                RolesService.getRoleById(role.id)
                    .then(roleDetails => {
                        let claims = [];
                        this.state.mappedRoleDetails.forEach((value, claim) => {
                            if (value) {
                                claims.push(claim);
                            }
                        });
                        roleDetails = {
                            ...roleDetails,
                            claims: claims
                        };
                        RolesService.updateRole(roleDetails.id, roleDetails)
                            .then(result => {
                                this.props.triggerAddRoleModal()
                            })
                            .catch(err => {
                                throw new Error(err);
                            })
                    })
                    .catch(err => {
                        throw new Error(err);
                    })
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    componentWillMount() {
        AccountService.getAccounts(10)
            .then(accounts => {
                let acc = accounts.map(account => {
                    return {value: account.id, label: account.name}
                });
                this.setState({
                    accounts: acc
                });
            })
            .catch(err => {
                throw new Error(err);
            });

    }

    render() {
        return (

            <div className="e-modal__wrapper">

                <div className="e-modal__head">

                    <h2 className="e-modal__title">Create new role</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerAddRoleModal()}}></span>

                </div>
                <div className="e-modal__body">
                    <div className="f-container create-new-role">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                            <div className="f-section">
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="role-name">Name</label>
                                        <Field
                                            component={InputField}
                                            type="text"
                                            className="field"
                                            validate={[required]}
                                            name="name"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>

                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="role-description">Description</label>
                                        <Field
                                            component={InputField}
                                            type="text"
                                            className="field"
                                            validate={[required]}
                                            name="description"
                                            placeholder="Enter role description"
                                            alt="Enter role description"
                                            tabIndex="2" />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-element">
                                        <label htmlFor="accountId">Parent account</label>
                                        <Field options={this.state.accounts}  validate={[required]} name="accountId" component={SelectInput} tabIndex="4" />
                                    </div>
                                </div>
                                <div className="f-row border-top">
                                    <h2 className="f-section__title">Permissions</h2>
                                    <div className="permission-list">
                                        {this.props.claimsByCategory.map((claimCategory, index) => {
                                            return <RoleCategory roleId={'new'} key={claimCategory.id} claimCategory={claimCategory} changeRoleValue={this.changeRoleValue.bind(this)} mappedRoleDetails={this.state.mappedRoleDetails}/>
                                        })}
                                    </div>
                                </div>
                                <div className="f-row" style={{margin: 0}}>
                                    <div className="f-element">
                                        <Field
                                            component={InputField}
                                            type={'hidden'}
                                            className="field"
                                            name="error"
                                            placeholder="Enter name"
                                            alt="Enter name"
                                            tabIndex="1" />
                                    </div>
                                </div>
                                <div className="f-row cta-container">
                                    <div className="f-element alpha">
                                        <button className={'cta'}>Create role</button>
                                    </div>
                                    <div className="f-element beta">
                                        <button className="light" onClick={(e) => {e.preventDefault(); this.props.triggerAddRoleModal()}}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

AddRole = reduxForm({
    form: 'addRole',
    destroyOnUnmout: true
})(AddRole);

function mapStateToProps(state) {
    return {
        keepDirtyOnReinitialize: true,
        formValues: getFormValues('addRole')(state),
        claims: state.config.claims
    }
}

export default connect(mapStateToProps)(AddRole);