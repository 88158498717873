import React, {Component} from 'react';

class Footer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <footer>
                <div className="wrapper">
                    Copyright © 2018 Acuant, Inc. All Rights Reserved.
                </div>
            </footer>
        )
    }
}

export default Footer;