import React, {useMemo} from 'react';
import {motion, AnimateSharedLayout} from "framer-motion";


const Pagination = (props) => {

    const {itemsCount, pageSize, currentPage, onPageChange} = props;

    const pagesCount = useMemo(() => Math.ceil(itemsCount / pageSize), [itemsCount, pageSize]);

    return (
        <motion.div initial={{opacity: 0}} exit={{opacity: 0}} animate={{opacity: 1}} className={'flex flex-row justify-between items-center font-Raleway'}>
            <div onClick={() => currentPage > 1 ? onPageChange(currentPage - 1) : undefined} className={`${currentPage === 1 ? 'font-medium disabled cursor-not-allowed' : 'font-bold  cursor-pointer'} uppercase`} style={{fontSize: 12}}>
                Prev
            </div>
            <div>
                    <ul className={'flex flex-row justify-between gap-2'}>
                        {
                            [...Array(pagesCount).keys()].map((_, i) => {
                                return (
                                    <li key={i} className={`flex cursor-pointer w-10 h-10 border-gray-100 border-2 items-center justify-center font-bold ${currentPage !== i + 1 ? 'bg-gray-200 border-gray-300' : 'bg-white'}`} style={{fontSize: 12}} onClick={() => onPageChange(i + 1)}>
                                        {i + 1}
                                    </li>
                                )
                            })
                        }
                    </ul>
            </div>
            <div onClick={() => currentPage !== pagesCount ? onPageChange(currentPage + 1) : undefined} className={`${currentPage === pagesCount ? 'font-medium disabled cursor-not-allowed' : 'font-bold  cursor-pointer'} uppercase`} style={{fontSize: 12}}>
                Next
            </div>
        </motion.div>
    )
}

export default Pagination;