import React, {Component} from 'react';
import Modal from 'react-modal';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import CreateSubscription from './Subscriptions/CreateSubscription';
import EditSubscription from './Subscriptions/EditSubscription';
import SubscriptionService from "../../services/api/Subscriptions";
import Subscription from './Subscriptions/Subscription';
import InlineMenu from "../shared/InlineMenu";
import * as qs from 'query-string';
import {isEmpty, isEqual, get} from 'lodash';
import {ReactComponent as ClearIcon} from "../../images/icons/i_clear.svg";

export default class Subscriptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            createSubModalIsOpen: false,
            editSubModalIsOpen: false,
            subscriptions: [],
            selectedSubscription: null,
            initialState: null,
            productFiltering: {
                isOpen: false,
                filterBy: 0,
                label: 'All product types'
            },
            subscriptionFiltering: {
                isOpen: false,
                filterBy: 0,
                label: 'All subscription types'
            },
            filteredRow: undefined
        }

        this.getData = this.getData.bind(this);
    }

    getData() {
        SubscriptionService.getAccountSubscription(this.props.account.id)
            .then(subscriptions => {
                subscriptions = subscriptions.map(sub => {
                    sub.authorization = JSON.parse(sub.authorization) || {};
                    return sub;
                });
                this.setState({
                    initialState: subscriptions,
                    subscriptions
                }, () => {
                    const queryString = qs.parse(this.props.location.search);
                    const row = this.state.initialState.filter((subscription) => subscription.id === queryString?.subscription_id)
                    if (!isEmpty(row)) {
                        this.setState({
                            ...this.state,
                            subscriptions: row,
                            filteredRow: get(row, '[0]', undefined)
                        })
                    }
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    componentDidMount() {
        Modal.setAppElement('body');
        this.getData();
    }

    triggerCreateSubModal() {
        this.setState({
            createSubModalIsOpen: !this.state.createSubModalIsOpen
        })
    }

    triggerEditSubModal(selectedSubscription) {
        this.setState({
            editSubModalIsOpen: !this.state.editSubModalIsOpen,
            selectedSubscription
        })
    }

    sortSubscriptions() {
        let subscriptions = this.state.initialState;
        if (this.state.productFiltering.filterBy > 0) {
            subscriptions = subscriptions.filter(subscription => subscription.documentProcessMode === this.state.productFiltering.filterBy)
        }
        if (this.state.subscriptionFiltering.filterBy > 0) {
            subscriptions = subscriptions.filter(subscription => subscription.licenseType === this.state.subscriptionFiltering.filterBy)
        }
        this.setState({
            subscriptions,
            productFiltering: {
                ...this.state.productFiltering,
                isOpen: false
            },
            subscriptionFiltering: {
                ...this.state.subscriptionFiltering,
                isOpen: false
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.productFiltering.filterBy !== prevState.productFiltering.filterBy) ||
            (this.state.subscriptionFiltering.filterBy !== prevState.subscriptionFiltering.filterBy)
        ) {
            this.sortSubscriptions()
        }

        if (this.state.editSubModalIsOpen !== prevState.editSubModalIsOpen) {
            this.getData();
        }

        if (this.state.createSubModalIsOpen !== prevState.createSubModalIsOpen && prevState.createSubModalIsOpen === true) {
            this.getData();
        }
        if (!isEmpty(this.props.location.search)) {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                const queryString = qs.parse(this.props.location.search);
                const row = this.state.initialState.filter((subscription) => subscription.id === queryString?.subscription_id)
                this.setState({
                    ...this.state,
                    subscriptions: row,
                    filteredRow: get(row, '[0]', undefined)
                })
            }
        } else {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                if (isEmpty(this.props.location.search)) {
                    this.setState({
                        ...this.state,
                        subscriptions: this.state.initialState,
                        filteredRow: undefined
                    })
                }
            }
        }
    }

    render() {
        return (
            <section className="subscriptions">
                <Modal
                    isOpen={this.state.createSubModalIsOpen}
                    contentLabel="Add New Account"
                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}
                    onRequestClose={() => this.setState({createSubModalIsOpen: false})}
                >
                    <CreateSubscription userClaims={this.props.userClaims} accountId={this.props.account.id} triggerCreateSubModal={this.triggerCreateSubModal.bind(this)}/>
                </Modal>

                <Modal
                    isOpen={this.state.editSubModalIsOpen}
                    contentLabel="Add New Account"
                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}
                    onRequestClose={() => this.setState({editSubModalIsOpen: false})}
                >
                    <EditSubscription userClaims={this.props.userClaims} subscriptionId={this.state.selectedSubscription} triggerEditSubModal={this.triggerEditSubModal.bind(this)}/>
                </Modal>

                <div className="wrapper">
                    <div className="page__head">
                        <h2 className="page__title small">Subscriptions</h2>
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['SubscriptionAddSubscription']}
                            oneperm
                        >
                            <a href="" className="cta" onClick={(e) => {e.preventDefault(); this.triggerCreateSubModal()}}>
                                <span className="mobile-only">+ Add New</span>
                                <span className="tablet-and-above">+ Add New Subscription</span>
                            </a>
                        </PermissibleRender>
                    </div>

                    <div className="flex justify-between">
                        <div className={'page__filters '}>
                            <InlineMenu menuIsOpen={this.state.productFiltering.isOpen} closeMenu={() => this.setState({productFiltering: {...this.state.productFiltering, isOpen: !this.state.productFiltering.isOpen}})}>
                                <a onClick={() => this.setState({productFiltering: {...this.state.productFiltering, isOpen: !this.state.productFiltering.isOpen}})} className={`filter__trigger ${this.state.productFiltering.isOpen ? 'is-active' : ''}`}>
                                    <span className="mobile-only">{this.state.productFiltering.label}</span>
                                    <span className="tablet-and-above">{this.state.productFiltering.label}</span>
                                </a>
                                <div className="filter__content inline-menu arrow center top">
                                    <ul className="filter">
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.productFiltering.filterBy === 0 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({productFiltering: {isOpen: true, filterBy: 0, label: 'All product types'}})}}
                                            >
                                                All Product types
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.productFiltering.filterBy === 1 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({productFiltering: {isOpen: true, filterBy: 1, label: 'Data capture'}})}}
                                            >
                                                Data capture
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.productFiltering.filterBy === 2 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({productFiltering: {isOpen: true, filterBy: 2, label: 'Authentication'}})}}
                                            >
                                                Authentication
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.productFiltering.filterBy === 3 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({productFiltering: {isOpen: true, filterBy: 3, label: 'Barcode only'}})}}
                                            >
                                                Barcode only
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </InlineMenu>

                            <InlineMenu menuIsOpen={this.state.subscriptionFiltering.isOpen} closeMenu={() => this.setState({subscriptionFiltering: {...this.state.subscriptionFiltering, isOpen: !this.state.subscriptionFiltering.isOpen}})} className="filter__container">
                                <a onClick={() => this.setState({subscriptionFiltering: {...this.state.subscriptionFiltering, isOpen: !this.state.subscriptionFiltering.isOpen}})} className={`filter__trigger ${this.state.subscriptionFiltering.isOpen ? 'is-active' : ''}`}>
                                    <span className="mobile-only">{this.state.subscriptionFiltering.label}</span>
                                    <span className="tablet-and-above">{this.state.subscriptionFiltering.label}</span>
                                </a>
                                <div className="filter__content inline-menu arrow center top">
                                    <ul className="filter">
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.subscriptionFiltering.filterBy === 0 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({subscriptionFiltering: {isOpen: true, filterBy: 0, label: 'All subscription types'}})}}
                                            >
                                                All subscription types
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.subscriptionFiltering.filterBy === 1 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({subscriptionFiltering: {isOpen: true, filterBy: 1, label: 'Production'}})}}
                                            >
                                                Production
                                            </a>
                                        </li>
                                        <li>
                                            <a href=""
                                               className={`filter-item ${this.state.subscriptionFiltering.filterBy === 2 ? 'is-selected' : ''}`}
                                               onClick={(e) => {e.preventDefault(); this.setState({subscriptionFiltering: {isOpen: true, filterBy: 2, label: 'Development'}})}}
                                            >
                                                Development
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </InlineMenu>
                        </div>
                        <div className="">
                            <div className="">
                                {this.state.filteredRow && (
                                    <div className={'px-4 py-2 bg-white border text-md flex flex-row items-center justify-center mt-6'}>
                                        <div className={'pr-2  text-gray-500'}>{this.state.filteredRow.name}</div>
                                        <ClearIcon className={'cursor-pointer'} onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(`/account/${this.props.account.id}/subscriptions`)
                                        }}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="entity-list sub-list">
                        {this.state.subscriptions.map(subscription => {
                            return <Subscription
                                userClaims={this.props.userClaims}
                                key={subscription.id}
                                subscription={subscription}
                                getData={this.getData}
                                triggerEditSubModal={this.triggerEditSubModal.bind(this)}

                            />
                        })}
                    </div>

                </div>
            </section>

        );
    }
}