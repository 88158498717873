import request from '../shared/request';


function getAccounts(levels = 2) {
    return request({
        url: levels === -1 ? `/api/accounts` : `/api/accounts?levels=${levels}`
    });
}

function filterByAccount(id) {
    return request({
        url: `/api/accounts?accountId=${id}&levels=2`
    });
}

function getAccount(id) {
    return request({
        url: `/api/accounts/${id}`
    })
}

function createAccount(data) {
    return request({
        url: `/api/accounts`,
        method: 'POST',
        data: data
    })
}

function editAccount(id, data) {
    return request({
        url: `/api/accounts/${id}`,
        method: 'PUT',
        data: data
    })
}

function deleteAccount(id) {
    return request({
        url: `/api/accounts/${id}`,
        method: 'DELETE'
    })
}

const AccountService = {
    getAccounts,
    filterByAccount,
    getAccount,
    createAccount,
    editAccount,
    deleteAccount
};

export default AccountService;