import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues, SubmissionError} from 'redux-form'
import {connect} from "react-redux";
import {InputField} from "../../../../inputs/standard";
import AccountService from "../../../../services/api/Account";
import {required} from "../../../../inputs/validations";
import {SelectInput} from "../../../../inputs/select";

class AccountDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            accountCreated: false
        }
    }

    componentWillMount() {
        AccountService.getAccounts(10)
            .then(accounts => {
                accounts = accounts.map(account => {
                    return {value: account.id, label: account.name}
                }).sort((a, b) => a.label.localeCompare(b.label));
                this.setState({
                    accounts: accounts
                });
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    onSubmit() {
        let {formValues} = this.props;
        let formValuesClone = {...formValues};

        /**
         * We need this in order to not modify the initial object
         * Otherwise the parent account field will reset
         */
        formValuesClone.accountId = formValuesClone.accountId.value;

        if (!this.state.accountCreated) {
            return AccountService.createAccount(formValuesClone)
                .then(account => {
                    this.props.populateAccountData(account);
                    this.setState({
                        accountCreated: true
                    })
                })
                .catch(err => {
                    throw new SubmissionError({...err.data});
                })
        } else {
            this.props.changeCurrentStep(2);
        }

    }

    getSaveButtonLabel() {
        return this.state.accountCreated ? 'Next: Add Admin' : 'Create Account';
    }

    getCancelButtonLabel() {
        return this.state.accountCreated ? 'Skip next step' : 'Cancel';
    }

    render() {
        return (
            <div className="f-container account-name">
                <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                    <div className="f-section">
                        <h3 className="f-section__title">Step 1 of 2 - Account name</h3>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="account-name">Account Name</label>
                                <Field
                                    className={'field'}
                                    component={InputField}
                                    disabled={this.state.accountCreated}
                                    type="text"
                                    validate={[required]}
                                    name="name"
                                    placeholder="Enter name"
                                    alt="Enter name"
                                    tabIndex="1"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="customer-id">Customer Id</label>
                                <Field
                                    component={InputField}
                                    disabled={this.state.accountCreated}
                                    className={'field'}
                                    type="text"
                                    validate={[required]}
                                    name="customerId"
                                    placeholder="Enter customer id ex: 123456789"
                                    alt="Enter customer id ex: 123456789"
                                    tabIndex="2"
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element">
                                <label htmlFor="accountId">Parent account</label>
                                <Field disabled={this.state.accountCreated}
                                       options={this.state.accounts}
                                       validate={[required]}
                                       name="accountId"
                                       component={SelectInput}
                                       tabIndex="4"
                                />
                            </div>
                        </div>
                        <div className="f-row" style={{margin: 0}}>
                            <div className="f-element">
                                <Field
                                    component={InputField}
                                    type={'hidden'}
                                    className="field"
                                    name="error"
                                    placeholder="Enter name"
                                    alt="Enter name"
                                    tabIndex="1" />
                            </div>
                        </div>
                        <div className="f-row cta-container">
                            <div className="f-element alpha">
                                <button className={'cta'}>{this.getSaveButtonLabel()}</button>
                            </div>
                            <div className="f-element beta">
                                <button className="light" onClick={(e) => {e.preventDefault(); this.props.triggerModal()}}>{this.getCancelButtonLabel()}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

AccountDetails = reduxForm({
    form: 'accountDetails',
    destroyOnUnmount: true
})(AccountDetails);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('accountDetails')(state),
        keepDirtyOnReinitialize: true,
    }
}

export default connect(mapStateToProps)(AccountDetails);