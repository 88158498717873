export const passwordRules = {
    minLength: {
        valid: (v) => v?.length >= 12,
        title: 'Minimum length 12 characters'
    },
    upperLowerCase: {
        valid: (v) => /[A-Z]+/.test(v) && /[a-z]+/.test(v),
        title: 'Both upper and lower-case letters'
    },
    atLeastOneNumber: {
        valid: (v) => /\d/g.test(v),
        title: 'At least one number (for example: 0-9)'
    },
    atLeastOneSpecialCharacter: {
        valid: (v) => /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(v),
        title: 'At least one special character (for example:!$^&*0)_+~= \\Il:"<>?,/)'
    }
}