import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector, getFormValues} from 'redux-form'
import {InputField} from "../../../inputs/standard";
import {connect} from "react-redux";
import AccountDetails from "./NewAccount/AccountDetails";
import UserDetails from "./NewAccount/UserDetails";

export default class NewAccountScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            newAccountData: null
        }
    }

    changeCurrentStep(currentStep) {
        this.setState({
            currentStep
        })
    }

    populateAccountData(newAccountData) {
        this.setState({
            newAccountData
        })
    }

    render() {
        return (
            <div className="e-modal__wrapper">
                <div className="e-modal__head">
                    <h2 className="e-modal__title">{this.state.currentStep === 2 ? this.state.newAccountData.name : 'Add new account'}</h2>
                    <span className="e-modal__close" onClick={(e) => {e.preventDefault(); this.props.triggerModal()}}></span>
                    <div className="steps__container">
                        <ul className="steps">
                            <li className={`step ${this.state.newAccountData !== null ? 'is-done' : 'is-current' }`}></li>
                            <li className={`step is-current`}></li>
                        </ul>
                    </div>
                </div>
                <div className="e-modal__body">
                    {this.state.currentStep === 1 &&
                    <AccountDetails
                        triggerModal={this.props.triggerModal}
                        populateAccountData={this.populateAccountData.bind(this)}
                        changeCurrentStep={this.changeCurrentStep.bind(this)}
                    />}
                    {this.state.currentStep === 2 &&
                    <UserDetails
                        triggerModal={this.props.triggerModal}
                        newAccountData={this.state.newAccountData}
                        changeCurrentStep={this.changeCurrentStep.bind(this)}
                    />}
                </div>
            </div>
        );
    }
}