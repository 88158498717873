import React, {useCallback} from 'react';
import {passwordRules} from "../utils/utils";


const PasswordRules = (props) => {

    const {password} = props;

    const checkValidRule = useCallback((validFn) => {
        return validFn(password);
    }, [password])

    return (
        <div className={'pt-4'}>
            {Object.values(passwordRules).map((rule) => {
                const isValid = checkValidRule(rule.valid);
                return (
                    <div className={`font-normal flex items-center ${isValid ? 'text-black' : 'text-gray-500'}`}>
                        <div className={`rounded-full w-4 h-4 mr-2 ${isValid ? 'bg-green-500' : 'bg-gray-300'}`} />
                        <span>{rule.title}</span>
                    </div>
                )
            })}
        </div>
    )
}



export default PasswordRules;