import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {required} from './../../inputs/validations';
import userManager from "./../../utils/userManager";

class Login extends Component {

    constructor(props) {
        super(props);
    }

    onSubmit() {
        userManager.signinRedirect();
    }

    render() {
        return (
            <div className="sign-in__container">

                <div className="alpha">
                    <h1 className="assure-id-logo-white"></h1>
                    <h2 className="extra-bold">Secure provisioning and management of accounts, users, and subscriptions, and generating transaction reports</h2>
                    <input onClick={this.onSubmit.bind(this)} type="submit" alt="Go to Login" tabIndex="4" value='Go to Login' style={{maxWidth: 272, margin: '0 auto'}}/>
                    {window.env.DISPLAY_US_GOVERNMENT_BANNER === true &&
                        <div className="sign-in__info">You are accessing a private information system that may contain U.S. Government data. All information on this computer system may be monitored, intercepted, recorded, read, copied, audited, and disclosed by and to authorized personnel for official purposes, including criminal investigations. Access to and use of this system is not approved for general public access. Permitted use of the system is limited to approved administrative functions required to operate and maintain the system and user functions to perform individual identification verification. System operations are approved for the following user actions only when the user is the appropriately identified individual or when validation of the individual's identity is permitted based on Government-authorized purposes: (1) Validation of physical security features of identification documents; (2) Validation of digital signature data associated with identification documents; (3) Validation of biometric and alphanumeric data contained in identification documents.<p/><br/>Unauthorized use of the system or its data is prohibited and may subject violators to criminal, civil, and/or administrative action. Any access attempts or use of this computer system by any person, whether authorized or unauthorized, constitutes consent to these terms.</div>
                    }
                </div>


            </div>

        )
    }
}

export default Login;