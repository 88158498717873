import React, {Component} from 'react';
import SwitchButton from 'lyef-switch-button';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import {connect} from "react-redux";


class RoleCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: true
        }
    }

    changeClaimValue(claim) {
        if (this.props.mappedRoleDetails.has(claim)) {
            let {mappedRoleDetails} = this.props;
            let value = this.props.mappedRoleDetails.get(claim);
            mappedRoleDetails.set(claim, !value);
            this.props.changeRoleValue(mappedRoleDetails);
        }

    }

    render() {
        if (!this.props.claimCategory) return null;
        if (!this.props.mappedRoleDetails) return null;
        let {claimCategory} = this.props;
        let items = [];
        {this.props.mappedRoleDetails.forEach((value, claim) => {
            if (claim.claimType === claimCategory.claimType) {
                items.push(<div className="permission-group__row" key={claim.id}>
                    <div className="permission-group__cell">
                        <span className="permission__name">{claim.description}</span>
                    </div>
                    <PermissibleRender
                        userPermissions={this.props.userClaims}
                        requiredPermissions={['RoleEditRole']}
                        oneperm
                    >
                        <div className="permission-group__cell">
                            <SwitchButton
                                id={`${this.props.roleId}-admin-can-add-accounts-${claim.id}`}
                                isChecked={value}
                                action={this.changeClaimValue.bind(this, claim)}
                            />
                        </div>
                    </PermissibleRender>
                </div>)
            }
        })}
        if (!items.length) return null;
        return(
            <div className="premission-group">
                <div className="permission-group__head">{claimCategory.claimType}</div>
                <div className="permission-group__list">
                    {items}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        claims: state.config.claims,
        userClaims: state.userClaims
    }
}

export default connect(mapStateToProps)(RoleCategory);