import React, {Component} from 'react';
import Modal from 'react-modal';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import AddRole from './Roles/AddRole';
import RolesService from "../../services/api/Roles";
import RoleItem from "./Roles/RoleItem";

export default class Roles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewRoleModalIsOpen: false,
            roles: null,
            claimsByCategory: []
        }
    }

    triggerAddRoleModal() {
        this.setState({
            addNewRoleModalIsOpen: !this.state.addNewRoleModalIsOpen
        })
    }

    getRoles() {
        RolesService.getRoles()
            .then(roles => {
                this.setState({
                    roles
                })
            })
            .catch(err => {
                throw new Error(err);
            });

        let claimsByCategory = this.props.config.claims.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj['claimType']).indexOf(obj['claimType']) === pos;
        });

        this.setState({
            claimsByCategory
        })
    }

    componentWillMount() {
        Modal.setAppElement('body');
        this.getRoles();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.addNewRoleModalIsOpen !== prevState.addNewRoleModalIsOpen) {
            this.getRoles();
        }
    }

    render() {
        return (
            <section className="roles">
                <Modal
                    isOpen={this.state.addNewRoleModalIsOpen}
                    contentLabel="Add New Account"

                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}

                    onRequestClose={() => this.setState({addNewRoleModalIsOpen: false})}
                >
                    <AddRole claimsByCategory={this.state.claimsByCategory} triggerAddRoleModal={this.triggerAddRoleModal.bind(this)}/>

                </Modal>

                <div className="wrapper">

                    <div className="page__head">

                        <h2 className="page__title">Roles</h2>
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['RoleAddRole']}
                            oneperm
                        >
                            <a href="" className="cta" onClick={(e) => {e.preventDefault(); this.triggerAddRoleModal()}} >
                                <span className="mobile-only">+ Add new</span>
                                <span className="tablet-and-above">+ Add new role</span>
                            </a>
                        </PermissibleRender>

                    </div>

                    <div className="entity-list role-list">
                        {!this.state.addNewRoleModalIsOpen && this.state.roles && this.state.roles.map(role => {
                            console.log(role);
                            return <RoleItem key={`${role.name}-${role.id}`} role={role} claimsByCategory={this.state.claimsByCategory}/>
                        })}
                    </div>

                </div>

            </section>

        );
    }
}