import React, {Component} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import Cookies from "universal-cookie";
import moment from 'moment';

class PrivateRoute extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', PrivateRoute.handleBeforeunload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', PrivateRoute.handleBeforeunload);
    }

    static handleBeforeunload() {
        let cookies = new Cookies();
        let expireTime = moment().add(1, 'days').unix() * 1000;
        cookies.set('user_last_seen', moment().unix() * 1000, {path: '/', expires: new Date(expireTime)})
    }

    render() {
        let { component: Component, user: user, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    user && !user.expired ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/auth/login" />
                    )
                }
            />
        )
    }

}

const mapStateToProps = state => ({
    user: state.oidc.user
});

export default connect(mapStateToProps)(PrivateRoute);