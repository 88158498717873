export function populateClaims(data) {
    return {
        type: '@@api-set-data/SET_CLAIMS',
        payload: data
    }
}

export function populateCurrentUserClaims(data) {
    return {
        type: '@@acuant/POPULATE_USER_CLAIMS',
        payload: data
    }
}