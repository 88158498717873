import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import InlineMenu from "../shared/InlineMenu";
import UserService from "../../services/api/Users";
import AccountService from "../../services/api/Account";
import moment from "moment/moment";
import {PermissibleRender} from "@brainhubeu/react-permissible";
import CustomModal from "../shared/Modal";
import DeleteModal from "../shared/DeleteModal";
import * as qs from 'query-string';
import {isEmpty, isEqual, get} from 'lodash';
import {ReactComponent as ClearIcon} from "../../images/icons/i_clear.svg";


export default class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortModalIsOpen: false,
            users: [],
            filterDirection: null,
            selectedUser: null,
            deleteModalIsOpen: false,
            filteredRow: undefined
        }

        this.onDelete = this.onDelete.bind(this);
    }

    async getUserData(id) {
        return UserService.getUserById(id);
    }

    componentDidMount() {
        Promise.all([UserService.getUsers(), AccountService.getAccounts()])
            .then(([users, accounts]) => {

                let usersWithRoles = users
                    .map(user => {
                        return {
                            id: user.id,
                            username: user.name,
                            fullName: user.fullName,
                            roles: user.roleNames.join(", "),
                            accountName: this.getAccount(accounts, user)
                        }
                    });

                return Promise.resolve(usersWithRoles);

            })
            .then((users) => {
                this.setState({
                    users,
                    initialState: users
                }, () => {
                    const queryString = qs.parse(this.props.location.search);
                    const row = this.state.initialState.filter((user) => user.id === queryString?.user_id)
                    if (!isEmpty(row)) {
                        this.setState({
                            ...this.state,
                            users: row,
                            filteredRow: get(row, '[0]', undefined)
                        })
                    }
                });
            })
            .catch(err => {
                throw new Error(err);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEmpty(this.props.location.search)) {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                const queryString = qs.parse(this.props.location.search);
                const row = this.state.initialState.filter((user) => user.id === queryString?.user_id)
                this.setState({
                    ...this.state,
                    users: row,
                    filteredRow: get(row, '[0]', undefined)
                })
            }
        } else {
            if (!isEqual(prevProps.location.search, this.props.location.search)) {
                if (isEmpty(this.props.location.search)) {
                    this.setState({
                        ...this.state,
                        users: this.state.initialState,
                        filteredRow: undefined
                    })
                }
            }
        }
    }

    getAccount(accounts, user) {
        const account = accounts.find(account => account.id === user.accountId);
        if (account) {
            return account.Name;
        }

        return "";
    }

    sortUsers(filterDirection) {

        let users = this.state.initialState;
        users = users.sort((a, b) => a.fullName.localeCompare(b.fullName));

        filterDirection = filterDirection === 'ascending' ? users : users.reverse();

        this.setState({
            users,
            filterDirection,
            sortModalIsOpen: false
        });
    }

    filterUsers(e) {
        if (!e.target.value.length) {
            this.sortUsers(this.state.filterDirection || 'ascending')
        } else {
            let users = this.state.initialState;
            users = users.filter(user => {
                if (user.fullName.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
                    return user;
                }
                if (user.username.toLowerCase().search(e.target.value.toLowerCase()) !== -1) {
                    return user;
                }
            });
            this.setState({
                users
            });

        }
    }

    onDelete() {
        UserService.deleteUser(this.state.selectedUser.id)
            .then(response => {
                let users = this.state.users.filter(u => u.id !== this.state.selectedUser.id);
                this.setState({
                    selectedUser: null,
                    deleteModalIsOpen: false,
                    users
                });
            })
            .catch(err => {

            })
    }

    render() {
        let displayActionsColumn = this.state.users.filter(user => !user.roles.split(",").includes('Administrator')).length;
        return (
            <section className="accounts">
                <div className="wrapper">
                    <div className="page__head">
                        <h2 className="page__title">Users</h2>
                    </div>
                    <div className="page__sub-head">
                        <InlineMenu menuIsOpen={this.state.sortModalIsOpen} closeMenu={() => this.setState({sortModalIsOpen: !this.state.sortModalIsOpen})} className="filter__container">
                            <a onClick={() => this.setState({sortModalIsOpen: !this.state.sortModalIsOpen})} className={`filter__trigger ${this.state.sortModalIsOpen ? 'is-active' : ''}`}>Sort By</a>
                            <div className="filter__content inline-menu arrow center top">
                                <ul className="filter">
                                    <li><a onClick={this.sortUsers.bind(this, 'ascending')} className={`filter-item ${this.state.filterDirection === 'ascending' ? 'is-selected' : ''}`}>A-Z</a></li>
                                    <li><a onClick={this.sortUsers.bind(this, 'descending')} className={`filter-item ${this.state.filterDirection === 'descending' ? 'is-selected' : ''}`}>Z-A</a></li>
                                </ul>
                            </div>
                        </InlineMenu>

                        <div className="">
                            <div className="">
                                {this.state.filteredRow && (
                                    <div className={'px-4 py-2 bg-white border text-md flex flex-row items-center justify-center'}>
                                        <div className={'pr-2  text-gray-500'}>{this.state.filteredRow.fullName}</div>
                                        <ClearIcon className={'cursor-pointer'} onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push('/dashboard/users')
                                        }}/>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="account__list">

                        <div className="account__head">

                            <div className="account__row">
                                <div className="account__cell alpha">
                                </div>
                                <div className="account__cell beta">User Name</div>
                                <div className="account__cell gamma">Account assigned</div>
                                <div className="account__cell delta">Roles</div>
                                {displayActionsColumn > 0 && <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['UserEditUser']}
                                    oneperm
                                >
                                    <div
                                        className="account__cell epsilon">Actions
                                    </div>
                                </PermissibleRender>
                                }
                            </div>
                        </div>

                        {this.state.users && this.state.users.map(user => {
                            return <div key={user.id} className={`account__item`}>
                                    <div className="account__row">
                                        <div className="account__cell alpha">
                                        </div>
                                        <div className="account__cell beta">
                                            <h2 className="account__name" >{user.fullName}</h2>
                                            <ul className="account__details">
                                                <li><strong className="roboto">{user.username}</strong></li>
                                            </ul>
                                        </div>
                                        <div className="account__cell gamma">
                                            <span className="value-tablet">{user.accountName}</span>
                                            <span className="value-mobile">{user.accountName}</span>
                                        </div>
                                        <div className="account__cell delta">
                                            <span className="value-tablet">{user.roles}</span>
                                            <span className="value-mobile">{user.roles}</span>
                                        </div>
                                        {displayActionsColumn > 0 &&
                                            <PermissibleRender
                                                userPermissions={this.props.userClaims}
                                                requiredPermissions={['UserEditUser']}
                                                oneperm
                                            >
                                                <div className="account__cell epsilon">
                                                    {!user.roles.split(",").includes('Administrator') && <a className="account____action" onClick={(e) => {e.preventDefault(); this.setState({deleteModalIsOpen: true, selectedUser: user})}}>Delete</a>}
                                                </div>
                                            </PermissibleRender>
                                        }
                                    </div>
                            </div>
                        })}
                        <DeleteModal data={this.state.selectedUser ? this.state.selectedUser.username : null} isOpen={this.state.deleteModalIsOpen} closeModal={() => this.setState({deleteModalIsOpen: false, selectedUser: null})} onDelete={this.onDelete}/>
                    </div>
                </div>
            </section>
        );
    }

}