import React, {Component, Fragment} from 'react';
import moment from "moment";
import { PermissibleRender } from '@brainhubeu/react-permissible';
import DeleteModal from "../../shared/DeleteModal";
import SubscriptionService from "../../../services/api/Subscriptions";
import userHasPermission from "../../../utils/checkPermissions";
import AuthorizeModal from "../../shared/AuthorizeModal";

export default class Subscription extends Component {

    constructor(props) {
        super(props);
        if (this.shouldHideAuthorizedProductSettings(props.userClaims)) props.subscription.authorization = {};

        this.state = {
            selectedSubscription: null,
            deleteModalIsOpen: false,
            authorizeModalIsOpen: false,
            deleteError: false,
        };

        this.onDelete = this.onDelete.bind(this);
    }

    shouldHideAuthorizedProductSettings(userClaims) {
        return !userHasPermission(userClaims, ['IsAcuant', 'IsCloud']);
    }

    renderByDocumentProcessMode(documentProcessMode) {
        switch (documentProcessMode) {
            case 1:
                return (
                    <Fragment>
                        <div className="sub-type__avatar data-capture"></div>
                        <h3 className="sub-type__name">Data capture</h3>
                    </Fragment>
                );
            case 2:
                return (
                    <Fragment>
                        <div className="sub-type__avatar auth"></div>
                        <h3 className="sub-type__name">Authentication</h3>
                    </Fragment>
                );
            case 3:
                return (
                    <Fragment>
                        <div className="sub-type__avatar bar-code"></div>
                        <h3 className="sub-type__name">Barcode only</h3>
                    </Fragment>
                );
            default:
                return null;
        }
    }

    renderByDocumentProcessModeMobile(documentProcessMode) {
        switch (documentProcessMode) {
            case 1: return 'Data capture';
            case 2: return 'Authentication';
            case 3: return 'Barcode only';
            default: return null;
        }
    }

    formatExpirationDate(expirationDate) {
        let date = moment(expirationDate);

        if (date instanceof moment) {
            if (date.year() === 9999) {
                return <li>Expires: <strong>Never</strong></li>
            } else {
                return <li>Expires on: <strong className="roboto">{date.format("MMM DD, YYYY")}</strong></li>
            }
        } else {
            throw new Error('Something went wrong');
        }
    }

    onDelete() {
        SubscriptionService.deleteSubscription(this.state.selectedSubscription.id)
            .then(response => {
                this.props.getData();
                this.setState({
                    selectedUser: null,
                    deleteModalIsOpen: false
                });
            })
            .catch(err => {
                this.setState({
                    deleteError: true
                })
            })
    }

    render() {
        let {subscription} = this.props;
        if (!subscription) return null;

        return(
            <Fragment>
                <div className="entity-list__item sub">
                    <div className="sub__row">
                        <div className="sub__cell beta">
                            <div className="sub__info">
                                <h2 className="sub__name">{subscription.name}</h2>
                                <h3 className="sub__type">{this.renderByDocumentProcessModeMobile(subscription.documentProcessMode)}</h3>
                                <ul className="sub__details">
                                    <li>ID: <strong className="roboto">{subscription.id}</strong></li>
                                    <li>Subscription type: <strong>{subscription.licenseTypeName}</strong></li>
                                    {this.formatExpirationDate(subscription.expirationDate)}
                                    <PermissibleRender
                                        userPermissions={this.props.userClaims}
                                        requiredPermissions={['IsAcuant']}
                                        oneperm
                                    >
                                        <li>Store PII: <strong className="roboto">{subscription.storePii.toString()}</strong></li>
                                        <PermissibleRender
                                            userPermissions={this.props.userClaims}
                                            requiredPermissions={['IsCloud', 'IsAcuant']}
                                        >
                                            <li>AssureID Enhanced: <strong className="roboto">{subscription.authorization['assureid/features/enhancedOcr'] ? "ON" : "OFF"}</strong></li>
                                            <li>AssureID Lite: <strong className="roboto">{subscription.authorization['assureid/features/lite'] ? "ON" : "OFF"}</strong></li>
                                            <li>Loqate Address Services: <strong className="roboto">{subscription.authorization['assureid/features/loqate'] ? "ON" : "OFF"}</strong></li>
                                            <li>Enhanced Tampering: <strong className="roboto">{subscription.authorization['assureid/features/enhancedTampering'] ? "ON" : "OFF"}</strong></li>
                                            <li>Standard Face recognition: <strong className="roboto">{subscription.authorization.facialMatch && subscription.authorization.facialMatch === 'social' ? "ON" : "OFF"}</strong></li>
                                            <li>Government Face recognition: <strong className="roboto">{subscription.authorization.facialMatch && subscription.authorization.facialMatch === 'nist' ? "ON" : "OFF"}</strong></li>
                                            <li>Enhanced Face recognition: <strong className="roboto">{subscription.authorization.facialMatch && subscription.authorization.facialMatch === 'enhanced' ? "ON" : "OFF"}</strong></li>
                                            <li>Passive Liveness: <strong className="roboto">{subscription.authorization.passiveLiveness ? "ON" : "OFF"}</strong></li>
                                            <li>Review: <strong className="roboto">{(subscription.authorization?.review?.toString() === 'true' || subscription.authorization?.review?.toString() === 'mock') ? "ON" : "OFF"}</strong></li>
                                            <li>Ozone: <strong className="roboto">{subscription.authorization.ozone ? "ON" : "OFF"}</strong></li>
                                        </PermissibleRender>
                                    </PermissibleRender>
                                </ul>
                                <div className="sub__users" style={{display: 'none'}}>
                                    <a className="users-count users-add-trigger">Assigned Users <span className="roboto">(3)</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="sub__cell gamma">
                            <PermissibleRender
                                userPermissions={this.props.userClaims}
                                requiredPermissions={['SubscriptionEditSubscription']}
                                oneperm
                            >
                                <a className="sub__edit" onClick={(e) => {e.preventDefault(); this.props.triggerEditSubModal(subscription.id)}}>Edit</a>
                                <a className="sub__delete" onClick={(e) => {e.preventDefault(); this.setState({deleteModalIsOpen: true, selectedSubscription: subscription})}}>Delete</a>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['IsAcuant', 'IsCloud']}
                                >
                                <a className="sub__authorize" onClick={(e) => {e.preventDefault(); this.setState({authorizeModalIsOpen: true, selectedSubscription: subscription})}}>Authorize</a>
                                </PermissibleRender>
                            </PermissibleRender>
                        </div>
                    </div>
                    {this.state.deleteModalIsOpen && <DeleteModal error={this.state.deleteError} errorMessage={'Subscription can\'t be deleted because there are one or more transactions associated with this subscription.'} data={this.state.selectedSubscription ? this.state.selectedSubscription.name : null} isOpen={this.state.deleteModalIsOpen} closeModal={() => this.setState({deleteModalIsOpen: false, selectedSubscription: null, deleteError: false})} onDelete={this.onDelete}/>}
                    {this.state.authorizeModalIsOpen && <AuthorizeModal subscription={this.state.selectedSubscription} isOpen={this.state.authorizeModalIsOpen} closeModal={() => this.setState({authorizeModalIsOpen: false, selectedSubscription: null})}/>}
                </div>
            </Fragment>
        )
    }
}