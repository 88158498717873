import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {Switch, Redirect, Route} from "react-router-dom";

export default class AccountSubNavigation extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        let {id} = this.props;

        return (

            <div className="user__sub-navigation sub-navigation">

                <div className="wrapper">

                    <ul className="tabs">
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['SubscriptionViewSubscriptions']}
                            oneperm
                        >
                            <li><NavLink className="tab" to={`/account/${id}/subscriptions`} activeClassName="is-current">Subscriptions</NavLink></li>
                        </PermissibleRender>
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['UserViewUsers']}
                            oneperm
                        >
                            <li><NavLink className="tab" to={`/account/${id}/users`} activeClassName="is-current">Users</NavLink></li>
                        </PermissibleRender>
                        <PermissibleRender
                            userPermissions={this.props.userClaims}
                            requiredPermissions={['ReportingGetTransactionsRequest']}
                            oneperm
                        >
                            <li><NavLink className="tab" to={`/account/${id}/reports`} activeClassName="is-current">Reports</NavLink></li>
                        </PermissibleRender>

                    </ul>

                </div>

            </div>

        );
    }
}