import React, {Component} from 'react';
import CustomModal from "./Modal";
import {InputField} from "../../inputs/standard";
import {required} from "../../inputs/validations";
import {Field, getFormValues, reduxForm} from "redux-form";
import SubscriptionService from "../../services/api/Subscriptions";
import {connect} from "react-redux";
import {toast} from "react-hot-toast";


class AuthorizeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
    }

    componentDidMount() {
        this.props.initialize({
            id: this.props.subscription.id,
            name: this.props.subscription.name
        })
    }

    onSubmit(values) {
        toast.promise(
            SubscriptionService.getToken(this.props.subscription.id).then(response => {
                console.log(response)
                this.props.change('bearer', response.access_token)
            }),
            {
                loading: 'Creating token',
                success: <b>Token created!</b>,
                error: <b>An unknown error occurred.</b>,
            }
        );
    }


    render () {
        let {subscription, isOpen, closeModal} = this.props;
        return (
            <CustomModal width={'40%'} isOpen={isOpen} closeModal={() => closeModal()}>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <div className="e-modal__head">
                        <h2 className="e-modal__title">Authorize Subscription</h2>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>

                        <div className="f-row">
                            <div className="f-element action">
                                <label htmlFor="subscription-id">Subscription Name</label>
                                <Field
                                    component={InputField}
                                    type="text"
                                    disabled
                                    name="name"
                                    tabIndex="2"
                                    className="field"
                                    validate={[required]}
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element action">
                                <label htmlFor="subscription-id">Subscription Id</label>
                                <Field
                                    component={InputField}
                                    type="text"
                                    disabled
                                    name="id"
                                    tabIndex="2"
                                    className="field"
                                    validate={[required]}
                                />
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-element action">
                                <label htmlFor="subscription-id">Bearer Token</label>
                                <Field
                                    component={InputField}
                                    type="text"
                                    disabled
                                    name="bearer"
                                    tabIndex="2"
                                    className="field"
                                />
                                <div style={{paddingLeft: 8}} onClick={() => {
                                    navigator.clipboard.writeText(this.props.formValues.bearer)
                                    this.setState({copied: true})
                                    setTimeout(() => {
                                        this.setState({copied: false})
                                    }, 2000)
                                }}>
                                    <a className="action-copy">{this.state.copied ? 'Copied' : 'Copy'}</a>
                                </div>
                            </div>
                        </div>
                        <div className="f-row cta-container">

                            <div className="f-element alpha">
                                <button className={'cta'}>Generate Token</button>
                            </div>

                            <div className="f-element beta">
                                <button onClick={e => {e.preventDefault();closeModal() }} className="light">Cancel</button>
                            </div>

                        </div>
                    </form>
                </div>
            </CustomModal>
        );
    }
};

AuthorizeModal = reduxForm({
    form: 'authorizeModal',
    destroyOnUnmount: true,
})(AuthorizeModal);

function mapStateToProps(state) {
    return {
        formValues: getFormValues('authorizeModal')(state)
    }
}

export default connect(mapStateToProps, null)(AuthorizeModal);
