import React, {useCallback, useState} from 'react';
import {motion} from "framer-motion";
import {take} from 'lodash';
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import Pagination from "./Pagination";

const pageSize = 27;

const GlobalSearchColumn = (props) => {

    const {data, column, expanded, triggerExpand, hideSearchResults, search} = props;
    const [page, setPage] = useState(1);

    const {data: paginatedData} = useQuery(['paginatedItem', column.title, page, search], () => {
        return data.slice((page - 1) * pageSize, page * pageSize);
    });
    const history = useHistory();

    const getTitle = useCallback((row) => {
        if (column.title === 'Accounts') {
            return row?.name
        }
        if (column.title === 'Users') {
            return row?.fullName
        }
        if (column.title === 'Subscriptions') {
            return row?.name
        }
    }, [column]);

    const getSubtitle = useCallback((row) => {
        if (column.title === 'Accounts') {
            return `${row?.subscriptionCount ?? 0} Subscriptions - ${row?.accountCount ?? 0} Child Accounts`
        }

        if (column.title === 'Users') {
            return `${row?.subscriptionCount ?? 0} Subscriptions`
        }
        if (column.title === 'Subscriptions') {
            return row?.id
        }
    }, [column]);

    const navigateToItem = useCallback((row) => {
        if (column.title === 'Accounts') {
            hideSearchResults();
            setTimeout(() => history.push(`/dashboard/accounts?account_id=${row?.id}`, {item: row}), 300)
        }
        if (column.title === 'Users') {
            hideSearchResults();
            setTimeout(() => history.push(`/account/${row.accountId}/users?user_id=${row.id}`), 300)
        }
        if (column.title === 'Subscriptions') {
            hideSearchResults();
            setTimeout(() => history.push(`/account/${row.accountId}/subscriptions?subscription_id=${row.id}`), 300);
        }
    }, [column, history])

    return (
        <motion.div exit={{opacity: 0}} initial={{opacity: 0}} animate={{opacity: 1}} key={column.title} className={`flex-1 flex flex-col justify-between px-10 ${expanded ? '' : 'border-r-2 '} pt-8 h-full overflow-hidden`}>
            <div>
                <motion.div className={'font-bold flex justify-between items-center'}>
                    <span style={{fontSize: 18}}>{column.title}</span>
                    {data.length > 0 && (<span className={`uppercase cursor-pointer`} onClick={triggerExpand} style={{fontSize: 12, color: '#D52B1E'}}>See {expanded ? 'less' : 'all'}</span>)}
                </motion.div>
                <div className={`grid flex-col ${expanded ? ' grid-cols-3' : ' grid-cols-1'} pt-3`}>
                    {(expanded ? paginatedData : take(data, 9))?.map((item, index) => {
                        return (
                            <motion.div onClick={() => navigateToItem(item)} key={item.id} className={'flex flex-col pt-3 cursor-pointer'}>
                                <span className={'font-bold truncate'} style={{fontSize: 14}}>
                                    {getTitle(item)}
                                </span>
                                {column.title === 'Users' && (
                                    <span className={'font-medium truncate'} style={{fontSize: 12}}>
                                        {item.name}
                                    </span>
                                )}
                                <span className={'font-medium truncate'} style={{fontSize: 12}}>
                                {getSubtitle(item)}
                                </span>
                                {column.title === 'Subscriptions' && (
                                    <span className={'font-medium truncate'} style={{fontSize: 12}}>
                                        {item?.licenseTypeName}
                                    </span>
                                )}
                            </motion.div>
                        )
                    })}
                </div>
            </div>
            {expanded && <Pagination itemsCount={data?.length ?? 0} pageSize={pageSize} currentPage={page} onPageChange={(_page) => setPage(_page)}/>}
        </motion.div>
    )
}

export default GlobalSearchColumn;