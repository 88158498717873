import request from '../shared/request';

function getAccountSubscription(id = null) {
    let url = id ? `/api/subscriptions?accountId=${id}` : `/api/subscriptions?accountId`;
    return request({
        url
    })
}

function getSubscriptionById(id) {
    return request({
        url: `/api/subscriptions/${id}`
    })
}

function editSubscription(id, data) {
    return request({
        url: `/api/subscriptions/${id}`,
        method: 'PUT',
        data: data
    })
}

function createSubscription(data) {
    return request({
        url: `/api/subscriptions`,
        method: 'POST',
        data: data
    })
}

function deleteSubscription(id) {
    return request({
        url: `/api/subscriptions/${id}`,
        method: 'DELETE'
    })
}

function getAuthorization(id) {
    return request({
        url: `/api/subscriptions/${id}/authorization`,
        method: 'GET'
    })
}

function getToken(id) {
    return request({
        url: `/api/subscriptions/${id}/token`,
        method: 'GET'
    })
}

function updateAuthorization(id, data) {
    return request({
        url: `/api/subscriptions/${id}/authorization`,
        method: 'PUT',
        data
    }, {
        'Content-type': 'application/json',
        'Accept': 'application/json'
    })
}

const SubscriptionService = {
    getAccountSubscription,
    getSubscriptionById,
    editSubscription,
    createSubscription,
    deleteSubscription,
    getAuthorization,
    updateAuthorization,
    getToken
};
export default SubscriptionService;