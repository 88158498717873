import React, {Component} from 'react';
import Login from "./Login";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Route} from "react-router-dom";


class Auth extends Component{

    constructor(props) {
        super(props);
    }


    render() {
        return (
			<div className="page credentials">
                <Route path={`${this.props.match.url}/login`} render={(props) => <Login />} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return state;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);