import React, {Component} from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {Switch, Redirect, Route} from "react-router-dom";
import { PermissibleRender } from '@brainhubeu/react-permissible';

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Subscriptions from "./SubscriptionsList";
import Users from "./Users";
import Reports from "./Reports";
import AccountSubNavigation from "./AccountSubNavigation";
import AccountService from "../../services/api/Account";
import {connect} from "react-redux";
import EditAccount from "../Dashboard/Accounts/EditAccount";
import Modal from "react-modal";
import DeleteModal from "../shared/DeleteModal";
import {queryClient} from "../../App";

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            editAccountModalIsOpen: false,
            deleteModalIsOpen: false
        }
        this.getData = this.getData.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    getData() {
        let {id} = this.props.match.params;
        AccountService.getAccount(id)
            .then(account => {
                console.log(account);
                this.setState({
                    account
                })
            })
            .catch(err => {
                throw new Error(err);
            })
    }

    componentWillMount() {
        this.getData()
    }

    onDelete() {
        AccountService.deleteAccount(this.state.account.id)
            .then(() => {
                queryClient.refetchQueries(['accounts']);
                this.props.history.push('/dashboard/accounts')
            })
            .catch(err => {
                this.setState({
                    deleteError: true
                })
                console.log(err);
            })
    }

    render() {
        if (!this.state.account) return null;
        let {id} = this.props.match.params;
        let {account} = this.state;
        return (
            <div className="page">

                <header className="header">
                    <div className="header__wrapper">
                        <div className={'flex h-full justify-between'}>
                            <div className={'h-full flex'}>
                                <a className="header__back" onClick={(e) => {e.preventDefault();this.props.history.push('/dashboard/accounts');}}>back to accounts</a>
                                <h1 className="header__title">{account.name}</h1>
                            </div>
                            <div className={'flex items-center'}>
                                <PermissibleRender
                                    userPermissions={this.props.userClaims}
                                    requiredPermissions={['AccountEditAccount']}
                                    oneperm
                                >
                                    <div className="bg-white px-6 py-2 font-bold cursor-pointer" onClick={() => this.setState({editAccountModalIsOpen: true})}>
                                        <a style={{color: '#D52B1E'}} className="" >Edit</a>
                                    </div>
                                    <div className={'ml-4 bg-transparent font-bold text-white px-6 py-2 border border-white cursor-pointer'} onClick={() => this.setState({deleteModalIsOpen: true, selectedAccount: account})}>
                                        <a className="account____action">Delete</a>
                                    </div>
                                </PermissibleRender>
                            </div>
                        </div>
                    </div>

                </header>

                <AccountSubNavigation userClaims={this.props.userClaims} id={id}/>

                <Switch>
                    <Route exact={true} path={`${this.props.match.url}/subscriptions`} render={(props) => <Subscriptions account={account} {...this.props} />} />
                    <Route exact={true} path={`${this.props.match.url}/users`} render={(props) => <Users account={account} {...this.props} />} />
                    <PermissibleRender
                        userPermissions={this.props.userClaims}
                        requiredPermissions={['ReportingGetTransactionsRequest']}
                        oneperm
                    >
                        <Route exact={true} path={`${this.props.match.url}/reports`} render={(props) => <Reports account={account} {...this.props} />} />
                    </PermissibleRender>
                </Switch>

                <Footer />
                <Modal
                    isOpen={this.state.editAccountModalIsOpen}
                    contentLabel="Edit Account"
                    portalClassName="e-modal"
                    overlayClassName="e-modal__overlay"
                    className="e-modal__content"
                    bodyOpenClassName="e-modal--is-open"
                    htmlOpenClassName="prevent-scroll"
                    shouldFocusAfterRender={false}
                    onRequestClose={() => this.setState({editAccountModalIsOpen: false})}
                >
                    <EditAccount selectedAccount={this.state.account.id} triggerEditAccountModal={() => {
                        this.getData()
                        this.setState({editAccountModalIsOpen: false});
                    }}/>

                </Modal>
                {this.state.deleteModalIsOpen && <DeleteModal error={this.state.deleteError} errorMessage={'Account can\'t be deleted because there are one or more transactions associated with this account.'} data={this.state.account ? this.state.account.name : null} isOpen={this.state.deleteModalIsOpen} closeModal={() => this.setState({deleteModalIsOpen: false, deleteError: false})} onDelete={this.onDelete}/>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userClaims: state.userClaims
    }
};

export default connect(mapStateToProps, null)(Account)